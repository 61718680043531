import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';
// import { ILineBarChartDataInterface } from './chart-interfaces';

interface ILineGraph {
  chartData: any;
  uuid: string;
  title?: string;
  height?: number;
  width?: number;
  minYValue?: number;
  maxYValue?: number;
  stepsY?: number;
  scales?: any;
  isCurrency?: boolean;
  options?: any
}

const LineGraph: React.FC<ILineGraph> = (props) => {

  const id = props.uuid || 'line';

  useEffect(() => {
    const ctx = document.getElementById(id);
    // @ts-ignore
    const ctx2 = ctx.getContext('2d');
    const gradient = ctx2.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, 'rgb(239,244,251)');
    gradient.addColorStop(1, 'rgb(255,255,255)');

    props.chartData.datasets[0].backgroundColor = gradient
    // @ts-ignore
    const chartInstance = new Chart(ctx, {
      type: 'line',
      data: {
        labels: props.chartData.labels,
        datasets: props.chartData.datasets,
      },
      options: {
        ...props.options,
        maintainAspectRatio: false,
      },
    });

    return () => {
      chartInstance.destroy();
    };
  }, [props]);

  return (
    <div>
      <canvas id={id} width={props.width || 300} height={props.height || 100} />
    </div>
  );
};

export default LineGraph;
