import HttpClient from "../../api/HttpClient";

import { call } from "redux-saga/effects";
const client = new HttpClient();

async function updateUserInfoRequestAPI(payload: any) {
    try {
      const response = await client.post(`api/user/upsert-my-user-field-data`, payload.updateUserInfo,
      );
      return response;
    } catch (e) {
      return e;
    }
  }

export const userSaga = {

  *updateMyUserInfoRequest(action: any) {
    console.log("calling UPDATE_MY_USER_ACTION in saga")
    let success = false;
    let response;
    try {
      // @ts-ignore
      response = yield call(updateUserInfoRequestAPI, action.payload);
      const successData = response.data;
      success = true;
      if (action.onSuccess) {
          action.onSuccess(successData);
        }
    } catch (e) {
      // tslint:disable-next-line
      console.log(e)
    }
    if (!success) {
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  };
  
  export default userSaga;
  