import { isObject } from "lodash";
import { actionIds } from "../../actions/actionIds";
import { autoReduce } from "./auto-reduce";
import { IMainState } from "../../interfaces/rootState";
import { IDocumentReference } from "../../stack-shared/interfaces/model/document-reference.interface";
import { ISystemSettings } from "../../stack-shared/interfaces/model/system-settings.interface";
import { IDocumentReferenceType } from "../../stack-shared/interfaces/model/document-reference-type.interface";
import { ensureArray } from "../../stack-shared/utils/gen-utils";
import { getDocumentAIStateKeyFlexible } from "../../constants";
import { ITopSearch } from "../../stack-shared/interfaces/model/top-search.interface";
// import { IComplianceInsight } from "../../stack-shared/interfaces/model/compliance-insight.interface";

function getTopOccurrences(arr: any) {
  // Filter out undefined values before processing
  const filteredArr = arr.filter((item: any) => item !== undefined);

  const counts = filteredArr.reduce((acc: any, cur: any) => {
      acc[cur] = (acc[cur] || 0) + 1;
      return acc;
  }, {});

  return Object.entries(counts)
      .sort((a: any, b: any) => b[1] - a[1]) // Sort by occurrence, descending
      .slice(0, 10) // Keep only top 10
      .map(([title, count]) => ({ title, count, checked: true })); // Return objects with title and count
}
interface IComplianceInsight {
  id?: number;
  name?: string;
  text?: string;
  created_by?: number;
  updated_by?: number;
  created_at?: Date;
  updated_date?: Date;
}

const loggedUser = {} as any;
let socketId = null
if (window?.localStorage?.email) {
  loggedUser.email = window?.localStorage?.email;
  loggedUser.name = window?.localStorage?.name;
  loggedUser.id = window?.localStorage?.id;
  loggedUser.isAdmin = window?.localStorage?.isAdmin;
  loggedUser.roles = ensureArray(window?.localStorage?.roles);
}

if (window?.localStorage?.socketId) {
  socketId = window.localStorage.socketId;
}

const initialState = {
  solrResults: {} as any,
  caseLawResults: {} as any,
  solrQuick: {} as any,
  solrRelated: {} as any,
  solrRegulations: {} as any,
  userInfo: {
    results: []
  } as any,
  errorMessage: {} as any,
  error: {} as any,
  info: {} as any,
  success: {} as any,
  document_references: {} as { [key: string]: IDocumentReference },
  documentReferenceTypes: {} as { [key: string]: IDocumentReferenceType },
  complianceInsights: {} as { [key: string]: IComplianceInsight },
  socketId,
  loading: false,
  loggedUser: loggedUser as any,
  aiResult: {
    guidance: {},
    caseLaw: {}
  } as any,
  system_settings: {} as ISystemSettings,
  adminMessages: {
    ingest: []
  } as any,
  topSearches: [] as ITopSearch[] ,
};

const mainState = (state: any = initialState, action: any): IMainState => {
  if (action.normalized || action.removeKeys || action.overrides || action.mergeKeys) {
    return autoReduce(action, state);
  }
  // console.log("DEBUG", action.type)
  switch (action.type) {
    case actionIds.ADMIN_MESSAGE:
      let currentAdminMessages = { ...state.adminMessages }; // Create a shallow copy of adminMessages
  
      if (action.payload?.context) {
          if (!currentAdminMessages[action.payload.context]) {
              currentAdminMessages[action.payload.context] = [];
          }
  
          // Create a new array to ensure immutability
          currentAdminMessages[action.payload.context] = [
              ...currentAdminMessages[action.payload.context], // Spread existing messages
              action.payload, // Add the new message
          ];
  
          // Ensure the array length does not exceed 20
          if (currentAdminMessages[action.payload.context].length > 100) {
              currentAdminMessages[action.payload.context] = currentAdminMessages[action.payload.context].slice(-100);
          }
      }
  
      console.log("returning?", currentAdminMessages);
      return {
          ...state,
          adminMessages: currentAdminMessages,
      } as any;
    case actionIds.CLEAR_ADMIN_MESSAGES:
      return {
        ...state,
        adminMessages: {
          ingest: []
        },
      } as any;
    case actionIds.ERROR:
      if (isObject(action.payload)) {
        state.error = action.payload;
        return { ...state } as any;
      }
      break;
    case actionIds.SUCCESS:
      if (isObject(action.payload)) {
        state.success = action.payload;
        return { ...state } as any;
      }
      break;
    case actionIds.INFO:
      console.log("in info reducer", action.payload)
      if (isObject(action.payload)) {
        state.info = action.payload;
        return { ...state } as any;
      }
      break;
    case actionIds.LOGOUT:
      window.localStorage.clear();
      return { ...initialState,
        loggedUser: {}
       } as any;
    case actionIds.SIGNIN_SUCCESS:
      const successData = action.payload;
      console.log("successData", successData)
      window.localStorage.jwt = successData.jwt;
      window.localStorage.email = successData?.user?.user_email;
      window.localStorage.name = successData?.user?.user_nicename;
      window.localStorage.id = successData?.user?.id;
      window.localStorage.socketId = successData?.user?.socket_id;
      window.localStorage.roles = successData?.roles;
      console.log("should set it", successData)
      let isAdmin = false;
      if (successData.isAdmin) {
        window.localStorage.isAdmin = true;
        isAdmin = true;
      }
      else{
        window.localStorage.isAdmin = false;
      }
      const now = new Date();
      now.setTime(now.getTime() + 1 * 3600 * 1000);
      window.localStorage.expires = now;
      return {
        ...state,
        loggedUser: {
          email: successData?.user?.user_email,
          id: successData?.user?.id,
          name: successData?.user?.user_nicename,
          roles: successData?.roles,
          isAdmin
        },
        socketId: successData?.user?.socket_id
      };
    case actionIds.CLEAR_SEARCH_CACHE:
      return {
        ...state,
        solrResults: null,
        caseLawResults: null,
      };;
    case actionIds.SOLR_SEARCH_SUCCESS:
      if (action.data.response && action.data.response.docs) {

          if (action.data?.facet_counts?.facet_fields?.entity_person){
            action.data.entity_person = action.data.facet_counts.facet_fields.entity_person.slice(0, 10).map((x: any) => {
              return {
                title: x.title,
                count: x.count,
                checked: true
              }
            })
          }
          if (action.data?.facet_counts?.facet_fields?.entity_organization){
            action.data.entity_organization = action.data.facet_counts.facet_fields.entity_organization.slice(0, 10).map((x: any) => {
              return {
                title: x.title,
                count: x.count,
                checked: true
              }
            })
          }
          if (action.data?.facet_counts?.facet_fields?.entity_location){
            action.data.entity_location = action.data.facet_counts.facet_fields.entity_location.slice(0, 10).map((x: any) => {
              return {
                title: x.title,
                count: x.count,
                checked: true
              }
            })
          }
      

        action.data.response.docs.forEach((doc: any) => {
          doc.highlighting = action.data.highlighting[doc.id];
        });
      }
      return {
        ...state,
        solrResults: action.data,
      };
    case actionIds.CASE_LAW_SEARCH_SUCCESS:
      if (action.data.response && action.data.response.docs) {
        action.data.response.docs.forEach((doc: any) => {
          doc.highlighting = action.data.highlighting[doc.id];
        });


          if (action.data?.facet_counts?.facet_fields?.entity_person){
            action.data.entity_person = action.data.facet_counts.facet_fields.entity_person.slice(0, 10).map((x: any) => {
              return {
                title: x.title,
                count: x.count,
                checked: true
              }
            })
          }
          if (action.data?.facet_counts?.facet_fields?.entity_organization){
            action.data.entity_organization = action.data.facet_counts.facet_fields.entity_organization.slice(0, 10).map((x: any) => {
              return {
                title: x.title,
                count: x.count,
                checked: true
              }
            })
          }
          if (action.data?.facet_counts?.facet_fields?.entity_location){
            action.data.entity_location = action.data.facet_counts.facet_fields.entity_location.slice(0, 10).map((x: any) => {
              return {
                title: x.title,
                count: x.count,
                checked: true
              }
            })
          }

      }
      return {
        ...state,
        caseLawResults: action.data,
      };
    case actionIds.SOLR_QUICK_SUCCESS:
      return {
        ...state,
        solrQuick: action.data,
      };
    case actionIds.LOGIN_ERROR:
      return {
        ...state,
        errorMessage: { error: "Incorrect Password. Please Try Again." },
      };
    case actionIds.PASSWORD_ERROR:
      return {
        ...state,
        errorMessage: { error: "This Email does not exist in our system. Please Try Again." },
      };
    case actionIds.SOLR_REGULATIONS_SUCCESS:
      return {
        ...state,
        solrRegulations: action.data,
      };
    case actionIds.SOLR_RELATED_SUCCESS:
      return {
        ...state,
        solrRelated: action.data,
      };
    case actionIds.GET_USER_SUCCESS:
      return {
        ...state,
        userInfo: action.data,
      };
      case actionIds.CLEAR_AI_RESULT:
        const aiResult = {
          ...state.aiResult,
        }

        const clearData = {
          context: '',
          source_nodes: [],
          text: '',
          uuid: action.payload.uuid
        }

        aiResult[getDocumentAIStateKeyFlexible(action.payload.context)] = clearData

        return {
          ...state,
          aiResult
        };
    case actionIds.SET_AI_RESULT:
      
      const documentTypeKey = getDocumentAIStateKeyFlexible(action.payload.document_type)
      let newState = {
        ...state
      }

      let documentState = state.aiResult[documentTypeKey]
      console.log("reducing", action)
      if (documentState?.uuid && documentState?.uuid === action?.payload?.compare_uuid){

        newState.aiResult = {
          ...newState.aiResult,
          [documentTypeKey]: {
            ...action.payload
          }
        }

      }
      return newState;
    case actionIds.MERGE_AI_RESULT:
      const aiStateKey = getDocumentAIStateKeyFlexible(action?.payload?.context)

      //action?.payload?.context
      let currentValue = {...state.aiResult[aiStateKey]};
      if (currentValue?.uuid && currentValue?.uuid === action?.payload?.uuid){
        currentValue.text += action?.payload?.text || "";
      }
      return {
        ...state,
        aiResult: { 
          ...state.aiResult,
          [aiStateKey]: { ...currentValue }, 
        },
      };
      case actionIds.START_LOADING_SUCCESS:
        state.loading = true;
        return { ...state };
      case actionIds.STOP_LOADING_SUCCESS:
        state.loading = false;
        return { ...state };
  }
  return state;
};

export default mainState;