export const REACT_APP_WP_DOMAIN = process.env.REACT_APP_WP_DOMAIN;
export const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN;
export const REACT_APP_SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN;
export const REACT_APP_USE_AI = process.env.REACT_APP_USE_AI;
export const SOLR_ADMIN_URL = process.env.SOLR_ADMIN_URL;

export const REGULATIONS_DATA = [
  { reg: "1900", title: "[Reserved]" },
  { reg: "1901", title: "[Reserved]" },
  {
    reg: "1902",
    title: "State Plans for Standards",
  },
  { reg: "1903", title: "Inspections, Citations, and Proposed Penalties" },
  {
    reg: "1904",
    title: "Recording and Reporting Occupational Injuries and Illnesses",
  },
  {
    reg: "1905",
    title:
      "Rules of Practice under 1970 Health Act",
  },
  {
    reg: "1906",
    title:
      "Administration Witnesses and Documents in Private Litigation [Reserved]",
  },
  { reg: "1908", title: "Consultation Agreements" },
  { reg: "1910", title: "Occupational Safety and Health Standards" },
  {
    reg: "1911",
    title:
      "Rules of Procedure",
  },
  { reg: "1912", title: "Advisory Committees on Standards" },
  {
    reg: "1912a",
    title: "National Advisory Committee",
  },
  {
    reg: "1913",
    title:
      "Access to Employee Medical Records",
  },
  {
    reg: "1915",
    title: "Shipyard Employment",
  },
  { reg: "1917", title: "Marine Terminals" },
  { reg: "1918", title: "Longshoring" },
  { reg: "1919", title: "Gear Certification" },
  {
    reg: "1920",
    title:
      "Longshoring Procedure for Variations",
  },
  {
    reg: "1921",
    title:
      "Longshoring Rules of Practice",
  },
  {
    reg: "1922",
    title:
      "Longshoring Investigational Hearings",
  },
  {
    reg: "1924",
    title:
      "Workshops & Rehab Facilities",
  },
  {
    reg: "1925",
    title: "Federal Service Contracts",
  },
  { reg: "1926", title: "Construction" },
  { reg: "1927", title: "[Reserved]" },
  {
    reg: "1928",
    title: "Occupational Safety and Health Standards for Agriculture",
  },
  {
    reg: "1949",
    title:
      "Office of Training and Education Occupational Safety and Health Administration",
  },
  {
    reg: "1952",
    title: "Approved State Plans for Enforcement of State Standards",
  },
  { reg: "1953", title: "Changes to State Plans" },
  {
    reg: "1954",
    title:
      "Procedures for the Evaluation and Monitoring of Approved State Plans",
  },
  {
    reg: "1955",
    title: "Procedures for Withdrawal of Approval of State Plans",
  },
  {
    reg: "1956",
    title:
      "State Plans for the Development and Enforcement of State Standards Applicable to State and Local Government Employees in States Without Approved Private Employee Plans",
  },
  {
    reg: "1960",
    title:
      "Basic Program Elements for Federal Employee Occupational Safety and Health Programs and Related Matters",
  },
  {
    reg: "1975",
    title:
      "Coverage of Employers Under the Williams Steiger Occupational Safety and Health Act of 1970",
  },
  {
    reg: "1977",
    title:
      "Discrimination Against Employees Exercising Rights Under the Williams Steiger Occupational Safety and Health Act of 1970",
  },
  {
    reg: "1978",
    title:
      "Procedures for the Handling of Retaliation Complaints Under the Employee Protection Provision of the Surface Transportation Assistance Act of 1982 (STAA), As Amended",
  },
  {
    reg: "1979",
    title:
      "Procedures for the Handling of Discrimination Complaints Under Section 519 of the Wendell H Ford Aviation Investment and Reform Act for the 21st Century",
  },
  {
    reg: "1980",
    title:
      "Procedures for the Handling of Retaliation Complaints Under Section 806 of the Sarbanes Oxley Act of 2002, As Amended",
  },
  {
    reg: "1981",
    title:
      "Procedures for the Handling of Discrimination Complaints Under Section 6 of the Pipeline Safety Improvement Act of 2002",
  },
  {
    reg: "1982",
    title:
      "Procedures for the Handling of Retaliation Complaints Under the National Transit Systems Security Act and the Federal Railroad Safety Act",
  },
  {
    reg: "1983",
    title:
      "Procedures for the Handling of Retaliation Complaints Under Section 219 of the Consumer Product Safety Improvement Act of 2008",
  },
  {
    reg: "1984",
    title:
      "Procedures for the Handling of Retaliation Complaints Under Section 1558 of the Affordable Care Act",
  },
  {
    reg: "1985",
    title:
      "Procedures for Handling Retaliation Complaints Under the Employee Protection Provision of the Consumer Financial Protection Act of 2010",
  },
  {
    reg: "1986",
    title:
      "Procedures for the Handling of Retaliation Complaints Under the Employee Protection Provision of the Seamans Protection Act (SPA), As Amended",
  },
  {
    reg: "1987",
    title:
      "Procedures for Handling Retaliation Complaints Under Section 402 of the FDA Food Safety Modernization Act",
  },
  {
    reg: "1988",
    title:
      "Procedures for Handling Retaliation Complaints Under Section 31307 of the Moving Ahead for Progress in the 21st Century Act (MAP-21)",
  },
  {
    reg: "1989",
    title:
      "Procedures for the Handling of Retaliation Complaints Under the Taxpayer First Act (TFA)",
  },
  {
    reg: "1990",
    title:
      "Identification Classification and Regulation of Potential Occupational Carcinogens",
  },
  {
    reg: "1991",
    title:
      "Procedures For The Handling Of Retaliation Complaints Under The Criminal Antitrust Anti-retaliation Act (CAARA).",
  },
  { reg: "1992", title: "[Reserved]" },
  { reg: "1993", title: "[Reserved]" },
  { reg: "1994", title: "[Reserved]" },
  { reg: "1995", title: "[Reserved]" },
  { reg: "1996", title: "[Reserved]" },
  { reg: "1997", title: "[Reserved]" },
  { reg: "1998", title: "[Reserved]" },
  { reg: "1999", title: "[Reserved]" },
];


export const getDocumentAIStateKeyFlexible = (flexString: string) => {
  switch(flexString?.toLocaleLowerCase()) {
    case "guidance":
      return "guidance";
    case "case-law":
    case "case_law":
    case "caselaw":
      return "caseLaw";
    case "regulations":
      return "guidance";
    default:
      console.log("unrecognized document type", flexString)
      return "unrecognized"
  }
}