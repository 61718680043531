export const GUIDANCE_TYPE_OPTIONS = [
    { key: "ComplianceDirective", value: "Compliance Directive" },
    { key: "IndustryGuidance", value: "Industry Guidance" },
    { key: "Interpretation", value: "Interpretation" },
    { key: "Publication", value: "Publication" },
    { key: "Regulation", value: "Regulation" },
    { key: "Statute", value: "Statute" },
  ];
  
  export const PUBLICATION_TYPE = [
    { key: "Booklet", value: "Booklet" },
    { key: "Brochure", value: "Brochure" },
    { key: "Card", value: "Card" },
    { key: "Ebook", value: "Ebook" },
    { key: "FactSheet", value: "Fact Sheet" },
    { key: "FatalFacts", value: "Fatal Facts" },
    { key: "GeneralGuidance", value: "General Guidance" },
    { key: "HazardAlert", value: "Hazard Alert" },
    { key: "InfoSheet", value: "InfoSheet" },
    { key: "Magnet", value: "Magnet" },
    { key: "OSHAAlert", value: "OSHA Alert" },
    { key: "PocketGuide", value: "Pocket Guide" },
    { key: "Poster", value: "Poster" },
    { key: "QuickCard", value: "QuickCard" },
    { key: "SHIB", value: "SHIB" },
  ];

  export const INDUSTRY_OPTIONS = [
    { key: "ALL", value: "ALL" },
    { key: "AgricultureAndForestry", value: "Agriculture and Forestry" },
    { key: "Construction", value: "Construction" },
    { key: "Energy", value: "Energy" },
    { key: "HealthCare", value: "Health Care" },
    { key: "Manufacturing", value: "Manufacturing" },
    { key: "Maritime", value: "Maritime" },
    { key: "Mining", value: "Mining" },
    { key: "RetailAndWholesaleTrade", value: "Retail and Wholesale Trade" },
    { key: "Services", value: "Services" },
    { key: "Telecommunications", value: "Telecommunications" },
    { key: "TransportationAndWarehousing", value: "Transportation and Warehousing" },
  ];
  