import React, { useEffect, useState } from "react";
import MaterialTable from "@material-table/core";
import { useDispatch, useSelector } from "react-redux";
import EditDocIcon from "@mui/icons-material/Edit";
import TrashIcon from "@mui/icons-material/Delete";
import StandardModal from "../Shared/StandardModal";
import { renderTextEllipsisClipboard, tableIcons } from "../Shared/MaterialTableShared";
import { COLORS, MARGIN_MEDIUM } from "../Shared/layout-constants";
import { Tooltip, Box, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { getComplianceInsightArray } from "../../store/selectors/main";
import {
  deleteComplianceInsight,
  getComplianceInsights,
  updateComplianceInsight,
} from "../../actions/complianceInsightActions";
// import { IComplianceInsight } from "../../stack-shared/interfaces/model/compliance-insight.interface";
import UpsertComplianceInsightForm from "../ComplianceInsight/UpsertComplianceInsightForm";
import { dateFormatted } from "../../utils/date-utils";
import { IComplianceInsight } from "../../stack-shared/interfaces/model/compliance-insight.interface";
import { showInfoMessage } from "../../actions/genericAction";

interface IComplianceInsightTable {
  title: string;
}

const iconStyle = {
  color: COLORS.BRAND_PRIMARY,
  fontSize: 24,
  cursor: "pointer",
};

const ComplianceInsightTable: React.FC<IComplianceInsightTable> = (props) => {
  const dispatch = useDispatch();
  const complianceInsights = useSelector(getComplianceInsightArray);

  const [upsertMode, setUpsertMode] = useState("create" as "create" | "update");
  const [upsertModalOpen, setUpsertModalOpen] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const [tableKey, setTableKey] = useState(0);
  const [selectedComplianceInsight, setSelectedComplianceInsight] =
    useState<IComplianceInsight | null>(null);

  const { title } = props;

  useEffect(() => {
    dispatch(getComplianceInsights({}));
  }, []);

  let columns = [] as any[];

  columns = columns.concat([
    {
      title: "Id",
      field: "id",
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
    },
    {
      title: "Name",
      field: "name",
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
    },
    {
      title: "Search Term(s)",
      field: "search_terms",
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
      render: (row: any) => {
        return row.search_terms?.length > 50
          ? `${row.search_terms.substring(0, 50)}...`
          : row.search_terms;
      },
    },
    {
      title: "Link 1",
      field: "link_1_title",
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
      render: (row: any) => {
        if (row.link_1_title) {
          return row.link_1_title;
        }
        return renderTextEllipsisClipboard(row.link_1, 47, dispatch, showInfoMessage)
      },
    },
    {
      title: "Link 2",
      field: "link_2_title",
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
      render: (row: any) => {
        if (row.link_2_title) {
          return row.link_2_title;
        }
        return renderTextEllipsisClipboard(row.link_2, 47, dispatch, showInfoMessage)
      },
    },
    {
      title: "Updated On",
      field: "updated_date",
      sorting: true,
      cellStyle: {
        width: 300,
        maxWidth: 300,
      },
      render: (row: any) => {
        return dateFormatted(row.updated_date, "yyyymmdd", "-");
      },
    },
  ]);

  columns = columns.concat([
    {
      title: "Actions",
      render: (row: any) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <Tooltip title={"Edit"} placement="top">
              <EditDocIcon
                style={iconStyle}
                onClick={() => {
                  setUpsertMode("update");
                  setSelectedComplianceInsight(row);
                  setUpsertModalOpen(true);
                }}
              />
            </Tooltip>
            <Tooltip title={"Delete"} placement="top">
              <TrashIcon
                style={iconStyle}
                onClick={() => {
                  setSelectedComplianceInsight(row);
                  setConfirmDeleteModalOpen(true);
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ]);

  const getComplianceInsightInitialValues = () => {
    if (upsertMode === "create") {
      return { id: null, name: "" };
    } else {
      return {...selectedComplianceInsight,
        search_terms: selectedComplianceInsight?.search_terms?.split(" | "),
      };
    }
  };

  return (
    <div style={{ margin: MARGIN_MEDIUM, textAlign: "left" }}>
      <Typography variant="h5" gutterBottom>
        Compliance Insights
      </Typography>
      <StandardModal
        key={upsertModalOpen ? "open" : "closed"}
        title={
          upsertMode === "create"
            ? "Create Compliance Insight"
            : "Update Compliance Insight"
        }
        open={upsertModalOpen}
        onClose={() => setUpsertModalOpen(false)}
        maxWidth={"md"}
        paragraphs={[``]}
        actions={[]}
        customActions={[]}
      >
        <>
          <Box sx={{ "& > :not(style)": { m: 1 } }}>
            <div>
              <UpsertComplianceInsightForm
                key={selectedComplianceInsight?.id}
                initialValues={getComplianceInsightInitialValues()}
                mode={upsertMode}
                onCreateSuccess={(data: any) => {
                  setUpsertModalOpen(false);
                }}
                onUpdateSuccess={(data: any) => {
                  setUpsertModalOpen(false);
                }}
              />
            </div>
          </Box>
        </>
      </StandardModal>

      <StandardModal
        title={"Confirm Delete Modal"}
        open={confirmDeleteModalOpen}
        onClose={() => setConfirmDeleteModalOpen(false)}
        paragraphs={[
          `Would you like to permanently delete ${selectedComplianceInsight?.name}?`,
        ]}
        actions={[
          {
            title: "Cancel",
            callback: () => {
              setConfirmDeleteModalOpen(false);
            },
          },
          {
            title: "Delete",
            callback: () => {
              dispatch(
                deleteComplianceInsight(
                  {
                    id: selectedComplianceInsight?.id,
                  },
                  () => {},
                  () => {},
                  "Compliance Insight Deleted"
                )
              );
              setConfirmDeleteModalOpen(false);
            },
          },
        ]}
        customActions={[]}
      />

      <div key={tableKey} style={{ display: "block" }}>
        <MaterialTable
          icons={tableIcons}
          title={
            <>
              <Button
                variant="contained"
                style={{ backgroundColor: COLORS.BRAND_PRIMARY }}
                startIcon={<AddIcon />}
                onClick={() => {
                  setUpsertMode("create");
                  setUpsertModalOpen(true);
                }}
              >
                Add
              </Button>
            </>
          }
          columns={columns}
          // style={{width: '100%'}}
          // style={mainStyle}
          data={complianceInsights}
          options={{
            sorting: true,
            search: true,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100, 200],
            paginationAlignment: "left",
          }}
        />
      </div>
    </div>
  );
};

export default ComplianceInsightTable;
