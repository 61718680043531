

import { Button, TextField } from "@mui/material/";
import { Form, Formik } from "formik";
import * as React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import StandardUploadInput from "../Shared/SnandardUploadInput";
import { getFileExtension } from "../../utils/general";
import { showErrorMessage, startLoading, stopLoading } from "../../actions/genericAction";
import { createDocumentReference, updateDocumentReference, uploadDocument } from "../../actions/documentActions";
import { DOCUMENT_TYPE } from "../../stack-shared/constants/document.constants";
import MultiSelect from "../General/MultiSelect";
import SingleSelect from "../General/SingleSelect";
import { COLORS } from "../Shared/layout-constants";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const INDUSTRY_OPTIONS = [
  { key: "ALL", value: "ALL" },
  { key: "Agriculture and Forestry", value: "AgricultureAndForestry" },
  { key: "Construction", value: "Construction" },
  { key: "Energy", value: "Energy" },
  { key: "Health Care", value: "HealthCare" },
  { key: "Manufacturing", value: "Manufacturing" },
  { key: "Maritime", value: "Maritime" },
  { key: "Mining", value: "Mining" },
  { key: "Retail and Wholesale Trade", value: "RetailAndWholesaleTrade" },
  { key: "Services", value: "Services" },
  { key: "Telecommunications", value: "Telecommunications" },
  { key: "Transportation and Warehousing", value: "TransportationAndWarehousing" },
];

const GUIDANCE_TYPE_OPTIONS = [
  { key: "Compliance Directive", value: "ComplianceDirective" },
  { key: "Industry Guidance", value: "IndustryGuidance" },
  { key: "Interpretation", value: "Interpretation" },
  { key: "Publication", value: "Publication" },
  { key: "Regulation", value: "Regulation" },
  { key: "Statute", value: "Statute" },
]

const PUBLICATION_TYPE = [
  { key: "Booklet", value: "Booklet" },
  { key: "Brochure", value: "Brochure" },
  { key: "Card", value: "Card" },
  { key: "Ebook", value: "Ebook" },
  { key: "Fact Sheet", value: "FactSheet" },
  { key: "Fatal Facts", value: "FatalFacts" },
  { key: "General Guidance", value: "GeneralGuidance" },
  { key: "Hazard Alert", value: "HazardAlert" },
  { key: "InfoSheet", value: "InfoSheet" },
  { key: "Magnet", value: "Magnet" },
  { key: "OSHA Alert", value: "OSHAAlert" },
  { key: "Pocket Guide", value: "PocketGuide" },
  { key: "Poster", value: "Poster" },
  { key: "QuickCard", value: "QuickCard" },
  { key: "SHIB", value: "SHIB" }
]





const UpsertDocumentForm: React.FC<any> = (props: any) => {
  const dispatch = useDispatch();

  // Default to "All" in create mode
  const initialSelectedIndustries =
    props.mode === "create" ? ["ALL"] : props.initialValues?.meta_data?.industries || [];

  const [selectedIndustries, setSelectedIndustries] = React.useState<any[]>(initialSelectedIndustries);
  const [selectedGuidanceType, setSelectedGuidanceType] = React.useState<string>(
    props.initialValues?.meta_data?.guidance_type?.[0] || ""
  );
  const [selectedPublicationType, setSelectedPublicationType] = React.useState<string>(props.initialValues?.meta_data?.publication_type || "");
  const [publicationDate, setPublicationDate] = React.useState<Date | null>(props.initialValues?.publicationDate ? new Date(props.initialValues.publicationDate) : null);

  const handleIndustryChange = (event: any) => {
    const rawValue = typeof event.target.value === "string" ? event.target.value.split(",") : event.target.value;
  
    // Sanitize and process the value
    const value = rawValue.filter((v: any) => v !== undefined);
  
    let updatedSelection;
  
    if (rawValue.includes("ALL") && !selectedIndustries.includes("ALL")) {
      // If "ALL" is selected by itself, clear all other selections and keep "ALL"
      updatedSelection = ["ALL"];
    } else if (selectedIndustries.includes("ALL") && !value.includes("ALL")) {
      // If "ALL" was previously selected and a new value is selected, replace with the new value
      updatedSelection = value.filter((v: any) => v !== "ALL");
    } else {
      // Otherwise, update normally, removing "ALL" if present
      updatedSelection = value.filter((v: any) => v !== "ALL");
    }
  
    setSelectedIndustries(updatedSelection);
  };


  const handleGuidanceTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedGuidanceType(event.target.value as string);
  };

  const handlePublicationTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedPublicationType(event.target.value as string);
  };


  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const handleSubmitDocument = (selectedFile: File, id?: number | null) => {
    // File submission logic remains unchanged
    return new Promise<void>((resolve, reject) => {
      try {
        getFileExtension(selectedFile!.name);
      } catch (e) {
        dispatch(showErrorMessage("Invalid File Extension."));
        return;
      }

      const uploadData = {
        id,
        file: selectedFile,
        filename: selectedFile.name,
        documentType: props.documentType,
      };

      dispatch(startLoading({}));
      dispatch(
        uploadDocument(
          uploadData,
          (data) => {
            dispatch(stopLoading({}));
            dispatch(
              updateDocumentReference(
                {
                  id,
                  s3_path: `${props.documentType}`,
                  original_file_name: selectedFile.name,
                  original_file_type: getFileExtension(selectedFile!.name),
                },
                () => {
                  props.onDocumentUploadSuccess(uploadData);
                },
                () => {},
                "Document uploaded successfully",
                true,
                true
              )
            );
          },
          (data) => {
            console.log("fail data", data);
          }
        )
      );
    });
  };

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div
        style={{
          width: "80%",
          maxWidth: "500px",
          backgroundColor: "white",
          padding: "20px",
        }}
      >
        <Formik
          initialValues={props.initialValues}
          validationSchema={Yup.object().shape({
            friendlyTitle: Yup.string().required("Friendly title required"),
          })}
          onSubmit={(values, { setFieldValue }) => {

            if (selectedGuidanceType === 'Publication' && !selectedPublicationType) {
              dispatch(showErrorMessage("Please select a publication type"));
              return;
            }

            if (props.mode === "update" && selectedFile) {
              handleSubmitDocument(selectedFile, values.id);
            } else {

              const metaData = {
                industries: selectedIndustries,
                guidance_type: selectedGuidanceType ? [selectedGuidanceType] : null,
                publication_type: selectedGuidanceType === 'Publication' && selectedPublicationType ? selectedPublicationType : null
              };

              if (values.id) {
                dispatch(
                  updateDocumentReference(
                    {
                      id: values.id,
                      friendly_title: values.friendlyTitle,
                      publication_date: values.publicationDate,
                      meta_data: metaData,
                    },
                    () => props.onDocumentUpdateSuccess(),
                    (data) => console.log("failed update data", data),
                    "Document updated successfully"
                  )
                );
              } else {
                dispatch(
                  createDocumentReference(
                    {
                      friendly_title: values.friendlyTitle,
                      document_type: props.documentType,
                      publication_date: values.publicationDate,
                      meta_data: metaData,
                    },
                    (data) => {
                      setFieldValue("id", data?.document_reference?.id);
                      props.onDocumentCreateSuccess();
                    },
                    (data) => console.log("fail data", data)
                  )
                );
              }
            }
          }}
        >
          {({ values, handleChange, setFieldValue, touched, errors }) => (
            <Form>
              <TextField
                fullWidth
                label="Friendly Title"
                name="friendlyTitle"
                value={values.friendlyTitle}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                error={touched.friendlyTitle && Boolean(errors.friendlyTitle)} // Highlights the field in red
                helperText={(touched.friendlyTitle && errors.friendlyTitle) as any} // Displays validation error message
                InputProps={{
                  style: {
                    fontSize: "16px",
                    height: "56px",
                    borderColor: touched.friendlyTitle && errors.friendlyTitle ? "red" : undefined, // Optional inline border color
                  },
                }}
              />

              {props.documentType === DOCUMENT_TYPE.GUIDANCE.toLowerCase() && (
                <>
                  <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                    <SingleSelect
                      options={GUIDANCE_TYPE_OPTIONS}
                      selectedValue={selectedGuidanceType}
                      onChange={handleGuidanceTypeChange}
                      label="Authority Level"
                      required={true}
                    />
                  </div>
                  {selectedGuidanceType === 'Publication' && <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                    <SingleSelect
                      options={PUBLICATION_TYPE}
                      selectedValue={selectedPublicationType}
                      onChange={handlePublicationTypeChange}
                      label="Publication Type"
                    />
                  </div>}
                  <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                    <MultiSelect
                      options={INDUSTRY_OPTIONS}
                      selectedValues={selectedIndustries}
                      onChange={handleIndustryChange}
                      label="Industry"
                    />
                  </div>

                </>
              )}

              {props.mode === "update" && (
                <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                  <StandardUploadInput
                    accept=".pdf"
                    onFileSelected={(data) => {
                      if (data[0]) {
                        setSelectedFile(data[0]);
                      }
                    }}
                  />
                </div>
              )}
<LocalizationProvider dateAdapter={AdapterDayjs}>
  <DatePicker
    label="Publication Date"
    value={values.publicationDate ? dayjs(values.publicationDate) : null} // Ensure value is a dayjs object or null
    onChange={(newValue, keyboardInputValue: any) => {
      if (newValue && dayjs(newValue).isValid()) {
        setFieldValue(
          "publicationDate",
          dayjs(newValue).toISOString() // Save as ISO string
        );
      } else if (keyboardInputValue === "") {
        setFieldValue("publicationDate", null); // Allow clearing the field
      } else {
        console.warn("Invalid date entered:", keyboardInputValue);
        // Optionally, handle invalid input cases here
      }
    }}
    slotProps={{
      textField: {
        fullWidth: true,
        margin: "normal",
        error: touched.publicationDate && Boolean(errors.publicationDate),
        helperText:
          touched.publicationDate &&
          typeof errors.publicationDate === "string"
            ? errors.publicationDate
            : undefined,
      },
    }}
  />
</LocalizationProvider>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: "20px", padding: "10px 0", fontSize: "16px", backgroundColor: COLORS.BRAND_PRIMARY }}
                
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpsertDocumentForm;
