import React, { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { Box, Button, CircularProgress, Typography, Alert } from '@mui/material';
import HttpClient from '../../api/HttpClient'; // Adjust the path
import LineGraph from '../Chart/LineGraph';
import { MARGIN_MEDIUM } from '../Shared/layout-constants';

const client = new HttpClient();

const HealthResultGraph: React.FC = () => {
  // Calculate default date range (last three days)
  const today = dayjs();
  const defaultStartDate = today.subtract(3, 'day');
  const defaultEndDate = today;

  // State variables
  const [startDate, setStartDate] = useState<Dayjs | null>(defaultStartDate);
  const [endDate, setEndDate] = useState<Dayjs | null>(defaultEndDate);
  const [chartData, setChartData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const chartOptions = {
    scales: {
      y: {
        min: 0,
        max: 1,
        ticks: {
          stepSize: 1, // Only display steps at 0 and 1
          callback: (value: number) => (value === 1 ? 'Up' : 'Down'), // Replace 1/0 with "Up"/"Down"
        },
      },
    },
  };

  const fetchHealthLogs = async () => {
    if (!startDate || !endDate) return;

    // Validate the date range (must not exceed 7 days)
    const diffInDays = endDate.diff(startDate, 'day');
    if (diffInDays > 7) {
      setError('The selected date range cannot exceed 7 days.');
      return;
    }

    setError(null); // Clear any previous errors
    setLoading(true);

    try {
      const url = 'api/health/history';
      const params = {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      };

      const response = await client.get(url, params);

      if (response.data) {
        const results = response.data;

        // Prepare chart data
        const labels = results.map((item: any) =>
          dayjs(item.timestamp).format('YYYY-MM-DD'),
        );

        // Map status fields to datasets
        const statusFields = [
          'mysqlStatus',
          'solrGuidanceStatus',
          'solrCaseLawStatus',
          'solrRegulationStatus',
          'milvusStatus',
          'nodeStatus',
        ];

        const datasets = statusFields.map((field) => ({
            label: field,
            data: results.map((item: any) => (item[field] === 'up' ? 1 : 0)),
            borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
              Math.random() * 255,
            )}, ${Math.floor(Math.random() * 255)}, 1)`,
            borderWidth: 2,
            tension: 0.4,
          }));
          
          setChartData({
            labels,
            datasets,
          });
          

        setShowGraph(true);
      }
    } catch (error) {
      console.error('Error fetching health logs:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (
    setter: React.Dispatch<React.SetStateAction<Dayjs | null>>,
    newValue: Dayjs | null,
  ) => {
    setter(newValue);
    setShowGraph(false); // Hide graph until "Search" is clicked
  };

  // Auto-run the search on component load
  useEffect(() => {
    fetchHealthLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginTop: MARGIN_MEDIUM }}>
      <Typography variant="h5" gutterBottom>
        Health Log History Graph
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box display="flex" gap={2} marginBottom={3}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => handleDateChange(setStartDate, newValue)}
            slotProps={{ textField: { fullWidth: false } }}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => handleDateChange(setEndDate, newValue)}
            slotProps={{ textField: { fullWidth: false } }}
          />
        </Box>
      </LocalizationProvider>
      {error && (
        <Box marginBottom={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <Box marginBottom={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={fetchHealthLogs}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Search'}
        </Button>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : showGraph && chartData ? (
        <LineGraph
          chartData={chartData}
          uuid="health-log-graph"
          height={400}
          width={600}
          options={chartOptions} // Pass the Y-axis label configuration here
        />
      ) : (
        !loading && <Typography>No data available</Typography>
      )}
    </div>
  );
};

export default HealthResultGraph;
