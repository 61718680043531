import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import { IRootState } from "../../interfaces/rootState";
import { getTopSearches } from "../../actions/aiActions";
import { MARGIN_LARGE } from "../Shared/layout-constants";

export interface ITopSearch {
    term?: string;
    phoneticTerm?: string;
    count?: number;
}

const TopSearchesTable: React.FC = () => {
    const dispatch = useDispatch();

    // Fetch top searches from the Redux store
    const topSearches = useSelector((state: IRootState) => state.mainState.topSearches);

    useEffect(() => {
        // Dispatch the action to fetch top searches
        dispatch(getTopSearches({}));
    }, [dispatch]);

    return (
        <div style={{ marginTop: MARGIN_LARGE }}>
            <Typography variant="h5" gutterBottom>
                Top Searches
            </Typography>
            <TableContainer component={Paper} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Search Term</strong></TableCell>
                            <TableCell><strong>Phonetic Term</strong></TableCell>
                            <TableCell align="right"><strong>Search Count</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {topSearches?.map((search: ITopSearch, index: number) => (
                            <TableRow key={index}>
                                <TableCell>{search.term || "-"}</TableCell>
                                <TableCell>{search.phoneticTerm || "-"}</TableCell>
                                <TableCell align="right">{search.count || 0}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

    );
};

export default TopSearchesTable;
