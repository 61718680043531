import React, { useState } from "react";
import {
  isArrayLength,
  transformSearchTerm,
} from "../../constants/genericHelpers";
import SearchViewCard from "./SearchViewCard";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";

type Props = {
  solrResultsItems: any[];
  guidanceHighlightingData: any[];
  caselawHighlightingData: any[];
  totalItems: number;
  searchTerm: string;
  searchTagTitle: string;
  parsedSearchKeyword: string;
  itemsPerPage: number;
  setItemsPerPage: (value: number) => void;
  currentPage: number;
  setCurrentPage: (value: number) => void;
};

export default function SearchViewResultsPanel({
  solrResultsItems,
  totalItems,
  searchTerm,
  searchTagTitle,
  itemsPerPage,
  caselawHighlightingData,
  guidanceHighlightingData,
  setItemsPerPage,
  currentPage,
  parsedSearchKeyword,
  setCurrentPage,
}: Props) {
  // Initialize state with URL parameters or defaults
  const [sortOption, setSortOption] = useState("");

  const handleSortChange = (event: SelectChangeEvent) => {
    setSortOption(event.target.value as string);
  };

  const handleItemsPerPageChange = (event: SelectChangeEvent) => {
    const newItemsPerPage = Number(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  return (
    <div className="middleContent">
      <div className="middleFilters">
        <div className="leftFilters">
          {totalItems ? (
            <p>
              Displaying {startItem} to {endItem} of{" "}
              <strong>
                {totalItems} results found for “
                {transformSearchTerm(parsedSearchKeyword)}”
              </strong>
            </p>
          ) : null}
        </div>
        {totalItems ? (
          <div className="rightFilters">
            <Box sx={{ minWidth: 120 }}>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  value={sortOption}
                  onChange={handleSortChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" disabled>
                    <span className="selectLabel">Sort by</span>
                  </MenuItem>
                  <MenuItem value="best">Best Match</MenuItem>
                  <MenuItem value="relevance">Relevance</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 120 }}>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  value={itemsPerPage.toString()}
                  onChange={handleItemsPerPageChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" disabled>
                    <span className="selectLabel">Results per page</span>
                  </MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        ) : null}
      </div>
      {isArrayLength(solrResultsItems) && totalItems ? (
        solrResultsItems.map((result: any, index: number) => {
          // Normalize the key to ensure matching
          const guidanceHighlightText =
            guidanceHighlightingData?.[Number(result.id)]; // Ensure both are strings

          const caselawHighlightText =
            caselawHighlightingData?.[Number(result.id)]; // Ensure both are strings

          return (
            <SearchViewCard
              key={index}
              result={result}
              searchTagTitle={searchTagTitle}
              guidanceHighlightText={guidanceHighlightText || null} // Pass as a prop if needed
              caselawHighlightText={caselawHighlightText || null} // Pass as a prop if needed
            />
          );
        })
      ) : (
        <div className="middleContentBox appliedFilterContentBox">
          <h2 className="title">No Results Found</h2>
          <p>
            Sorry, we couldn't find any results matching your search criteria.
          </p>
          <p>
            Try adjusting your filters or search terms to get better results.
          </p>
        </div>
      )}

      {isArrayLength(solrResultsItems) && totalItems ? (
        <div className="pagination">
          <Pagination
            count={Math.ceil(totalItems / itemsPerPage) || 0} // Use totalItems to calculate page count
            page={currentPage}
            onChange={handlePageChange}
            shape="rounded"
            sx={{
              "& .Mui-selected": {
                backgroundColor: "#F66E2F !important",
                color: "#ffffff",
              },
              "&.Mui-disabled": {
                background: "#F66E2F !important",
                color: "#ffffff",
              },
            }}
          />
        </div>
      ) : null}
    </div>
  );
}
