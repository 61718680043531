import * as React from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router";
import {
  caseLawSearchAction,
  searchGuidance,
  searchRegulations,
} from "../../actions/solrActions";
import { getDocumentAIStateKeyFlexible, REGULATIONS_DATA } from "../../constants";
import { IRootState } from "../../interfaces/rootState";
import {
  searchAIAction,
  updateAIFeedbackAction,
} from "../../actions/aiActions";
import {
  clearAIResultAction,
  setAIResultAction,
} from "../../actions/genericAction";
import { v4 as uuidv4 } from "uuid";

import TuneRoundedIcon from "@mui/icons-material/TuneRounded";

import "./SearchView.css";
import SearchViewContent from "./SearchViewContent";
import SearchViewSkeleton from "./SearchViewSkeleton";
import {
  filterRegulations,
  parseSearchInput,
  parseUrlToOriginal,
  validateFacetsFilter,
} from "../../constants/genericHelpers";
import SearchTabs from "./SearchTabs";
import SearchViewCoPilot from "./SearchViewCoPilot";
import { searchComplianceInsights } from "../../actions/complianceInsightActions";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { INDUSTRY_OPTIONS } from "../../constants/search-view.constants";

interface Paging {
  start: number;
  rows: number;
}

function cleanSearchString(search: any) {
  if (typeof search !== "string") {
    throw new Error("Input must be a string");
  }

  // Replace ",null" with an empty string and process the same logic as sanitizeSearchTerm
  const sanitizedSearch = search.replace(/,null/g, "").trim();

  // Use the same truncation logic as sanitizeSearchTerm
  const regex = /,\s*\d/;

  if (regex.test(sanitizedSearch)) {
    return sanitizedSearch.toLocaleLowerCase();
  }

  const commaIndex = sanitizedSearch.indexOf(",");
  if (commaIndex !== -1) {
    const afterComma = sanitizedSearch.substring(commaIndex + 1).trim();
    if (!/\d/.test(afterComma)) {
      return sanitizedSearch.toLocaleLowerCase();
    }
  }

  return commaIndex === -1
    ? sanitizedSearch.toLocaleLowerCase()
    : sanitizedSearch.substring(0, commaIndex).toLocaleLowerCase();
}

const checkAuthentication = () => {
  try {
    // If the checks pass, you can proceed with the app logic
    return null; // No navigation needed, proceed normally
  } catch (error) {
    console.error("Error checking authentication:", error);
    return <Navigate replace to="/" />;
  }
};

const reduceFilters = (filter: any) => {
  return Object.keys(filter).reduce((out: any, key: any) => {
    if (filter[key]?.checked) {
      out.push(key);
    }
    return out;
  }, []);
};

// Helper function to select the appropriate solr results
const getSolrResultsItems = (
  regulationsData: { response?: { docs?: any[] } },
  guidanceData: { docs?: any[]; data?: any[] },
  caseLawData: { docs?: any[]; data?: any[] },
  currentDocType: string
): any[] => {
  if (currentDocType === "guidance" && guidanceData?.docs?.length) {
    return guidanceData.docs; // Return guidance data if type is "guidance"
  }
  if (currentDocType === "case-law" && caseLawData?.docs?.length) {
    return caseLawData.docs; // Return guidance data if type is "guidance"
  }

  if (regulationsData?.response?.docs?.length) {
    return regulationsData.response.docs; // Default to regulations data
  }

  return []; // Default to an empty array if no valid data
};

const SearchView: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const aiIsSearching = useRef(false)

  const solrResults = useSelector((state: IRootState) => state.mainState.solrResults);
  const caseLawResults = useSelector((state: IRootState) => state.mainState.caseLawResults);

  const [entityPerson, setEntityPerson] = useState([] as any[]);
  const [entityLocation, setEntityLocation] = useState([] as any[]);
  const [entityOrganization, setEntityOrganization] = useState([] as any[]);

  const [entityPersonCaseLaw, setEntityPersonCaseLaw] = useState([] as any[]);
  const [entityLocationCaseLaw, setEntityLocationCaseLaw] = useState(
    [] as any[]
  );
  const [entityOrganizationCaseLaw, setEntityOrganizationCaseLaw] = useState(
    [] as any[]
  );



  const [searchTerm, setSearchTerm] = useState("");
  const [miningMethod, setMiningMethod] = useState(["Surface", "Underground"]);
  const [commodity, setCommodity] = useState(["Coal", "Metal", "Nonmetal"]);

  const [isSearching, setIsSearching] = useState(false);


  const [aiFinalResult, setAiFinalResult] = useState({
    guidance: {id: null, data: null, searchTerm: null},
    caseLaw: {id: null, data: null, searchTerm: null},
  } as any);

  const [showFilters, setShowFilters] = useState(false);

  const [aiFetching, setAiFetching] = useState(false);

  //OSHA Hooks
  const [regulationsData, setRegulationsData] = useState([] as any);
  const [guidanceData, setGuidanceData] = useState([] as any);
  const [guidanceFacets, setGuidanceFacets] = React.useState({
    publication_type: [],
    guidance_type: [],
    entity_organization: [],
    entity_location: [],
    entity_person: [],
  });
  const [caselawFacets, setCaselawFacets] = React.useState({
    entity_organization: [],
    entity_location: [],
    entity_person: [],
  } as any);
  const [caseLawData, setCaseLawData] = useState([] as any);
  const [guidanceHighlightingData, setGuidanceHighlightingData] = useState(
    [] as any
  );
  const [caselawHighlightingData, setCaselawHighlightingData] = useState(
    [] as any
  );
  const [complianceInsightsData, setComplianceInsightsData] = useState(
    [] as any
  );
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [industries, setIndustries] = useState<string[]>([]);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);

  const [regulationFilter, setRegulationFilter] = useState(null as any);
  const [guidanceFilter, setGuidanceFilter] = useState(null as any);
  const [caselawFilter, setCaselawFilter] = useState(null as any);

  const socketId = useSelector((state: IRootState) => state.mainState.socketId);

  const searchParams = new URLSearchParams(location.search);

  const industriesFilter = searchParams.get("industries");

  // Update industries from URL if present
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const industriesFilter = searchParams.get("industries");
    setSelectedIndustries(industriesFilter ? industriesFilter.split(",") : []);
  }, [location.search]);

  // Handle changes to industries
  const handleIndustryChange = (industry: string) => {
    const searchParams = new URLSearchParams(location.search);
    let industriesList = searchParams.get("industries")?.split(",") || [];

    if (industry === "ALL") {
      // If "ALL" is selected, deselect everything else and only include "ALL"
      if (industriesList.includes("ALL")) {
        industriesList = []; // Deselect "ALL"
      } else {
        industriesList = ["ALL"]; // Select "ALL"
      }
    } else {
      // Remove "ALL" if other industries are selected
      industriesList = industriesList.filter((i) => i !== "ALL");

      if (industriesList.includes(industry)) {
        industriesList = industriesList.filter((i) => i !== industry); // Deselect industry
      } else {
        industriesList.push(industry); // Add industry
      }
    }

    // Update the URL with the new industries list
    if (industriesList.length > 0) {
      searchParams.set("industries", industriesList.join(","));
    } else {
      searchParams.delete("industries"); // Remove the key if empty
    }

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    setSelectedIndustries(industriesList);
    onExecuteGuidanceSearch({ industries: industriesList });
  };

  const updateEntityResults = (
    entityKey: string,
    resultSet: any,
    setStateFunction: any,
    existingEntities: any
  ) => {
    const results = resultSet?.[entityKey];
    if (results) {
      setStateFunction(
        results.map(({ title, count }: { title: string; count: number }) => {
          const existing = existingEntities.find(
            (entity: any) => entity.title === title
          );
          return {
            title,
            count,
            checked: existing ? existing.checked : true,
          };
        })
      );
    }
  };

  useEffect(() => {
    updateEntityResults(
      "entity_person",
      solrResults,
      setEntityPerson,
      entityPerson
    );
    updateEntityResults(
      "entity_location",
      solrResults,
      setEntityLocation,
      entityLocation
    );
    updateEntityResults(
      "entity_organization",
      solrResults,
      setEntityOrganization,
      entityOrganization
    );
    updateEntityResults(
      "reg_body",
      solrResults,
      setEntityOrganization,
      entityOrganization
    );
    updateEntityResults(
      "reg_title",
      solrResults,
      setEntityOrganization,
      entityOrganization
    );
  }, [solrResults]);

  useEffect(() => {
    updateEntityResults(
      "entity_person",
      caseLawResults,
      setEntityPersonCaseLaw,
      entityPersonCaseLaw
    );
    updateEntityResults(
      "entity_location",
      caseLawResults,
      setEntityLocationCaseLaw,
      entityLocationCaseLaw
    );
    updateEntityResults(
      "entity_organization",
      caseLawResults,
      setEntityOrganizationCaseLaw,
      entityOrganizationCaseLaw
    );
  }, [caseLawResults]);

  useEffect(() => {
    checkAuthentication();
  }, []);

  const updateCommodity = (list: string[]) => {
    setCommodity(list);
  };

  const updateMining = (list: string[]) => {
    setMiningMethod(list);
  };

  const parsedSearchKeyword = parseUrlToOriginal(location?.search) || "";

  const currentPath = location?.pathname?.split("/");
  const currentDocType = currentPath?.[currentPath.length - 1];

  // Separate initial search triggered by parsedSearchKeyword
  useEffect(() => {
    if (parsedSearchKeyword && !aiFetching) {
      setSearchTerm(parsedSearchKeyword);
      searchButton(parsedSearchKeyword); // Only for initial search
    }
  }, [parsedSearchKeyword]);

  // Trigger onFetchRegsAndDocs when `currentPage` or `itemsPerPage` change
  useEffect(() => {
    if (currentPage && itemsPerPage) {
      onFetchRegsAndDocs(searchTerm, {
        start: (currentPage - 1) * itemsPerPage,
        rows: itemsPerPage,
      });
    }
    onSearchQueryWithAI();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
      onSearchQueryWithAI();
  }, [currentDocType]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page); // Update page without resetting to 1
    typeof window !== "undefined" &&
      window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleItemsPerPageChange = (value: number) => {
    setItemsPerPage(value);
    setCurrentPage(1); // Reset to page 1 only when items per page changes
    typeof window !== "undefined" &&
      window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const updateSearchQuery = (searchValue: string) => {
    setSearchTerm(searchValue); // Update the search term state

    const params = new URLSearchParams();
    // Get the current search params
    const searchParams = new URLSearchParams(location.search);

    // Update or set the `keyword` query param
    if (searchValue) {
      searchParams.set("keyword", searchValue);
      if (industries?.length) params.set("industries", industries.join(","));
    } else {
      searchParams.delete("keyword"); // Remove the param if the input is empty
    }

    // Push the updated URL with the new search params
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };


  const onSearchQueryWithAI = async () => {
    const uuid = uuidv4();

    const finalSearch = searchTerm || parsedSearchKeyword;

    if (!finalSearch) {
      return;
    }

    let currentDocTypeFinal = getDocumentAIStateKeyFlexible(currentDocType);

    if (!aiIsSearching.current && finalSearch!== aiFinalResult[currentDocTypeFinal]?.searchTerm) {
      await new Promise((resolve, reject) => {
        dispatch(clearAIResultAction({ uuid, context: currentDocTypeFinal }));
          aiIsSearching.current = true;
          setAiFetching(true);
          setAiFinalResult({
            ...aiFinalResult,
            [currentDocTypeFinal]: {
              searchTerm: finalSearch,
              id: null,
              data: null
            }
          })
          dispatch(
            searchAIAction(
              {
                query: finalSearch,
                document_type: currentDocTypeFinal,
                socketId,
                uuid,
                stream: true,
              },
              (data: any) => {
                if (data.uuid === uuid) {
                  console.log("Data from AI search:", data);
                  dispatch(
                    setAIResultAction({ ...data.result, compare_uuid: uuid, id: data.aiResultId })
                  );
                  setAiFinalResult({
                    ...aiFinalResult,
                    [currentDocTypeFinal]: {
                      searchTerm: finalSearch,
                      id: data.aiResultId,
                      data: data.result
                    }
                  });
                }
                aiIsSearching.current = false;
                setAiFetching(false);
                resolve(data);
              },
              (error) => {
                console.error("Error in AI search:", error);
                aiIsSearching.current = false;
                setAiFetching(false);
                reject(error);
              },
              "",
              true,
              false
            )
          );
      });
  
    }
    

  };

  const resetEntitiesStates = () => {
    setRegulationsData([]);
    setGuidanceData([]);
    setCaseLawData([]);
    setGuidanceHighlightingData([]);
    setCaselawHighlightingData([]);
  };

  const onFetchRegsAndDocs = async (
    searchKey: string,
    paging: Paging = { start: 0, rows: 10 }
  ) => {
    //Reset old state before fetching new data
    resetEntitiesStates();

    const search = cleanSearchString(
      searchKey || searchTerm || parsedSearchKeyword
    );
    const { keyword } = parseSearchInput(search) || {};
    setIsSearching(true);

    const searchParams =
      keyword && guidanceFilter
        ? {
            filters: { reg_num: guidanceFilter },
            search: keyword,
            paging,
          }
        : { search, paging };

    // Helper functions for each search action
    const searchRegulationsAsync = () =>
      new Promise((resolve) => {
        dispatch(
          searchRegulations(
            searchParams,
            (data) => {
              setRegulationsData(data?.data);
              resolve(data);
            },
            () => {},
            "",
            true,
            false
          )
        );
      });

    const searchGuidanceAsync = () =>
      new Promise((resolve) => {
        dispatch(
          searchGuidance(
            {
              search,
              paging,
              filters: { industries: industriesFilter },
            },
            (data) => {
              setGuidanceData(data?.response);
              setGuidanceHighlightingData(data?.highlighting);

              // Extract and set guidance facets
              const facets = data?.facet_counts?.facet_fields || {};
              setGuidanceFacets({
                publication_type: facets.publication_type || [],
                guidance_type: facets.guidance_type || [],
                entity_organization: facets.entity_organization || [],
                entity_location: facets.entity_location || [],
                entity_person: facets.entity_person || [],
              });

              resolve(data);
            },
            () => {},
            "",
            true,
            false
          )
        );
      });

    // Helper function for case law search
    const searchCaseLawAsync = () =>
      new Promise((resolve, reject) => {
        dispatch(
          caseLawSearchAction(
            {
              search,
              is_initial: false,
              paging,
              filters: {},
            },
            (data) => {
              setCaseLawData(data?.response);
              setCaselawHighlightingData(data?.highlighting);

              // Extract and set guidance facets
              const facets = data?.facet_counts?.facet_fields || {};
              setCaselawFacets({
                entity_organization: facets.entity_organization || [],
                entity_location: facets.entity_location || [],
                entity_person: facets.entity_person || [],
              });
              resolve(data);
            },
            (error) => {
              console.error("Case law search failed", error);
              reject(error);
            }
          ) as any
        );
      });

    // Helper function for compliance insights search
    const searchComplianceInsightsAsync = () =>
      new Promise((resolve, reject) => {
        dispatch(
          searchComplianceInsights(
            {
              search,
            },
            (data) => {
              setComplianceInsightsData(data.complianceInsights);
              resolve(data);
            },
            (error) => {
              console.error("Compliance insights search failed", error);
              reject(error);
            }
          ) as any
        );
      });

    // Run the first two search actions in parallel
    await Promise.all([
      searchRegulationsAsync(),
      searchGuidanceAsync(),
      searchCaseLawAsync(),
      searchComplianceInsightsAsync(),
    ]);
    setIsSearching(false);
  };

  const onExecuteGuidanceSearch = async (params: any) => {
    const search = cleanSearchString(searchTerm || parsedSearchKeyword);
    const { industries } = params || {};

    const paging = {
      start: (currentPage - 1) * itemsPerPage,
      rows: itemsPerPage,
    };

    await dispatch(
      searchGuidance(
        {
          search,
          paging,
          filters: {
            ...(guidanceFilter ? validateFacetsFilter(guidanceFilter) : {}),
            ...(industries ? { industries } : {}),
          },
        },
        (data) => {
          setGuidanceData(data?.response);
          setGuidanceHighlightingData(data?.highlighting);

          // Extract and set guidance facets
          const facets = data?.facet_counts?.facet_fields || {};
          setGuidanceFacets({
            publication_type: facets.publication_type || [],
            guidance_type: facets.guidance_type || [],
            entity_organization: facets.entity_organization || [],
            entity_location: facets.entity_location || [],
            entity_person: facets.entity_person || [],
          });
        },
        () => {},
        "",
        true,
        false
      )
    );
  };

  useEffect(() => {
    if (!guidanceFilter) return;
    onExecuteGuidanceSearch(null);
  }, [
    guidanceFilter,
    selectedIndustries,
    searchTerm,
    parsedSearchKeyword,
    currentPage,
    itemsPerPage,
  ]);

  const onExecuteCaseLawSearch = async (
    params: { filters?: Record<string, any> } = {}
  ) => {
    const search: string = cleanSearchString(searchTerm || parsedSearchKeyword);
    const { filters } = params;

    const paging = {
      start: (currentPage - 1) * itemsPerPage,
      rows: itemsPerPage,
    };

    await dispatch(
      caseLawSearchAction(
        {
          search,
          paging,
          filters: filters || {},
        },
        (data: {
          response?: any;
          highlighting?: any;
          facet_counts?: {
            facet_fields?: Record<string, any[]>;
          };
        }) => {
          setCaseLawData(data?.response);
          setCaselawHighlightingData(data?.highlighting);

          // Extract and set case law facets
          const facets = data?.facet_counts?.facet_fields || {};
          setCaselawFacets({
            entity_organization: facets.entity_organization || [],
            entity_location: facets.entity_location || [],
            entity_person: facets.entity_person || [],
          });
        },
        (error: unknown) => {
          console.error("Case law search failed", error);
        }
      ) as any // Explicit cast if necessary
    );
  };

  useEffect(() => {
    if (!caselawFilter) return;

    const params = {
      filters: {
        ...(caselawFilter ? validateFacetsFilter(caselawFilter) : {}),
      },
    };

    onExecuteCaseLawSearch(params);
  }, [
    caselawFilter,
    searchTerm,
    parsedSearchKeyword,
    currentPage,
    itemsPerPage,
  ]);

  useEffect(() => {
    if (!regulationFilter) return;

    const executeSearch = () => {
      const search = cleanSearchString(searchTerm || parsedSearchKeyword);

      const paging = {
        start: (currentPage - 1) * itemsPerPage,
        rows: itemsPerPage,
      };

      dispatch(
        searchRegulations(
          { search, paging, filters: regulationFilter },
          (data) => {
            setRegulationsData(data?.data);
          },
          () => {},
          "",
          true,
          false
        )
      );
    };

    executeSearch();
  }, [
    regulationFilter,
    searchTerm,
    parsedSearchKeyword,
    currentPage,
    itemsPerPage,
  ]);

  const searchButton = async (searchKey: string) => {
    try {
      await onFetchRegsAndDocs(searchKey, {
        start: (currentPage - 1) * itemsPerPage,
        rows: itemsPerPage,
      });

      // Start the AI search, indicating it's fetching
      await onSearchQueryWithAI();
    } catch (error) {
      console.error(`Error while searching regs, docs and AI`);
    }
  };

  return (
    <div className="searchViewWrapper">
      <div className="searchViewHeader">
        <div className="contentWidth">
          <div className="search">
            <div className="searchLeft">
              <InputBase
                placeholder="Search…"
                classes={{
                  root: "inputRoot",
                  input: "inputInput",
                }}
                value={searchTerm || " "}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    updateSearchQuery(searchTerm);
                  }
                }}
              />
            </div>
            <div
              className="searchRight"
              onClick={() => updateSearchQuery(searchTerm)}
            >
              <SearchIcon />
            </div>
            <div
              className="searchRight"
              onClick={() => setShowFilters(!showFilters)}
            >
              <TuneRoundedIcon />
            </div>
          </div>
          {showFilters && (
            <div className="filtersDropdown">
              <FormGroup>
                <Typography variant="h6">Industry</Typography>
                <div className="industryOptions">
                  {INDUSTRY_OPTIONS.slice(1).map((industry) => (
                    <FormControlLabel
                      key={industry.key}
                      control={
                        <Checkbox
                          checked={selectedIndustries.includes(industry.key)}
                          onChange={() => handleIndustryChange(industry.key)}
                          disabled={
                            selectedIndustries.includes("ALL") &&
                            industry.key !== "ALL"
                          } // Disable other options when "ALL" is selected
                        />
                      }
                      label={industry.value}
                    />
                  ))}
                </div>
              </FormGroup>
              <Typography
                variant="caption"
                style={{
                  fontFamily: "Label S", // Replace with actual style reference
                  marginTop: "8px",
                  color: "#555",
                }}
              >
                Industry filters apply to Regulations and Guidance documents
                only
              </Typography>
            </div>
          )}
          <SearchTabs searchTerm={searchTerm || parsedSearchKeyword} />
        </div>
      </div>
      <div className="searchViewBody">
        {isSearching ? (
          <SearchViewSkeleton />
        ) : (
          <SearchViewContent
            totalItems={
              currentDocType === "regulations"
                ? regulationsData?.response?.numFound
                : currentDocType === "guidance"
                ? guidanceData?.numFound
                : currentDocType === "case-law"
                ? caseLawData?.numFound
                : 0
            }
            solrResultsItems={getSolrResultsItems(
              regulationsData,
              guidanceData,
              caseLawData,
              currentDocType
            )}
            facetedNavFilters={
              regulationsData?.facet_counts
                ? filterRegulations(
                    regulationsData?.facet_counts,
                    REGULATIONS_DATA
                  )
                : []
            }
            facetedNavGuidanceFilters={
              currentDocType === "case-law"
                ? caselawFacets
                : guidanceFacets
            }
            complianceInsightsData={complianceInsightsData}
            renderCoPilot={
              <SearchViewCoPilot
                documentType={getDocumentAIStateKeyFlexible(currentDocType)}
                key={aiFinalResult?.[getDocumentAIStateKeyFlexible(currentDocType)]?.text}
                aiFinalResult={aiFinalResult}
                aiFetching={aiFetching}
                searchTerm={searchTerm}
                updateAIFeedback={(payload: any) => {
                  dispatch(
                    updateAIFeedbackAction(
                      payload,
                      () => {},
                      () => {},
                      "",
                      true,
                      false
                    )
                  );
                }}
                renderSkeleton={<SearchViewSkeleton />}
              />
            }
            guidanceHighlightingData={guidanceHighlightingData}
            caselawHighlightingData={caselawHighlightingData}
            searchTerm={searchTerm}
            itemsPerPage={itemsPerPage}
            parsedSearchKeyword={parsedSearchKeyword}
            setItemsPerPage={handleItemsPerPageChange}
            setCurrentPage={handlePageChange}
            currentPage={currentPage}
            regulationFilter={regulationFilter}
            setRegulationFilter={setRegulationFilter}
            guidanceFilter={guidanceFilter}
            setGuidanceFilter={setGuidanceFilter}
            caselawFilter={caselawFilter}
            setCaselawFilter={setCaselawFilter}
          />
        )}
      </div>
    </div>
  );
};

export default SearchView;
