export const richErrorObject = (e: any) => {
    const errorResponse = {
      success: false,
      error: {
        message: '',
        stack: '',
        additionalInfo: {} as any
      }
    };
  
    try {
      // Safely extract the error message
      if (e?.message) {
        errorResponse.error.message = e.message;
      } else {
        errorResponse.error.message = 'An unknown error occurred';
      }
  
      // Include the stack trace for debugging 
      if (e?.stack) {
        errorResponse.error.stack = e.stack;
      }
  
      // Extract additional error properties, if any
      Object.keys(e).forEach(key => {
        if (key !== 'message' && key !== 'stack' && key !== 'name') {
          errorResponse.error.additionalInfo[key] = e[key];
        }
      });
  
      return errorResponse;
    }
    catch (e) {
      console.log("Error creating rich error object")
    }
  
  }

  export function ensureArray(input: any) {
    if (Array.isArray(input)) {
      return input; // If it's already an array, return it
    }
    if (typeof input === 'string') {
      try {
        const parsed = JSON.parse(input.replace(/'/g, '"')); // Convert single quotes to double quotes for valid JSON
        return Array.isArray(parsed) ? parsed : [input]; // If parsed is an array, return it; otherwise, wrap the string
      } catch (error) {
        return [input]; // If parsing fails, wrap the string in an array
      }
    }
    if (input === undefined || input === null) {
      return []; // If undefined or null, return an empty array
    }
    return [input]; // Wrap other types in an array
  }

  export function pickProperties<T extends object>(obj: T, keys: (keyof T)[]): Partial<T> {
    const result: Partial<T> = {};
    keys.forEach((key) => {
      if (key in obj) {
        result[key] = obj[key];
      }
    });
    return result;
  }

  export const safeBool = (
    input: string | boolean,
    defaultValue = false,
  ): boolean => {
    try {
      let newInput = input;
      if (typeof input === 'string') {
        newInput = input.toLowerCase();
      }
      if (newInput === 't' || newInput === 'true' || newInput === true || newInput === "yes" || newInput === "accepted") {
        return true;
      }
      if (newInput === 'f' || newInput === 'false' || newInput === false || newInput === "no" || newInput === 'declined') {
        return false;
      }
      return defaultValue;
    } catch (e) {
      return defaultValue;
    }
  };