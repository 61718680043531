import React, { useEffect, useRef, useState } from "react";
import MaterialTable, { Column } from "@material-table/core";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSnackbar } from "notistack";
import HttpClient from "../../api/HttpClient";
import Loading from "../General/Loading";
import StandardModal from "../Shared/StandardModal";
import { dateFormatted } from "../../utils/date-utils";
import { tableIcons } from "../Shared/MaterialTableShared";
import { ToggleOn, ToggleOff, CheckCircle } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { updateBasicUserInfo } from "../../actions/userActions";
import { FormControlLabel, Switch, Typography } from "@mui/material";
import { MARGIN_MEDIUM } from "../Shared/layout-constants";

const client = new HttpClient();

interface IUserTable {
  title: string;
  fixed_query_params: Record<string, any>;
  onEditUser: (user: any) => void;
}

interface RowData {
  id: number;
  user_email: string;
  display_name: string;
  wp_role: string;
  user_registered: string;
  active: boolean;
  is_admin: number;
  is_active: boolean;
  user_status: number;
  user_activation_key?: string;
}

const UserTable: React.FC<IUserTable> = ({ title, fixed_query_params }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState<RowData | null>(
    null
  );
  const [tableKey, setTableKey] = useState(0);
  const [activationModalOpen, setActivationModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [toggleStates, setToggleStates] = useState<Record<number, boolean>>({}); // Track temporary toggle states
  const [filterAdmins, setFilterAdmins] = useState(false);
  const tableRef = useRef<any>(null);
  
  const toggleUserAccess = (user: RowData) => {
    // Update `toggleStates` immediately for UI feedback
    setToggleStates((prev) => ({
      ...prev,
      [user.id]: !(prev[user.id] ?? user.is_active), // Toggle the current state
    }));
  
    // Open the activation modal
    setSelectedUserData(user);
    setActivationModalOpen(true);
  };
  

  const handleMenuItemClick = (role: "Admin" | "User") => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const refreshTableData = async () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange(); // Trigger a table data refresh
    }
  
    setTimeout(() => {
      setToggleStates({}); // Clear temporary toggle states
    }, 500);
  };
  
  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange(); // Trigger a table data refresh
    }
  }, [filterAdmins]); // Runs whenever filterAdmins changes
  
  const fetchUsers = async (query: any) => {
    const params: Record<string, any> = {
      take: query.pageSize,
      skip: query.page * query.pageSize,
      search: query.search,
      ...fixed_query_params,
      filterAdmins
    };

    if (query.orderBy) {
      params.orderBy = query.orderBy.field;
    }
    if (query.orderDirection) {
      params.orderDirection = query.orderDirection;
    }

    const result = await client.get("api/user/query", params);

    return {
      data: result.data.data.map((user: RowData) => ({
        ...user,
        // Merge toggleStates into the fetched data
        is_active: toggleStates[user.id] ?? user.is_active,
      })),
      page: query.page,
      totalCount: result.data.total,
    };
  };

  const columns: Column<RowData>[] = [
    {
      title: "id",
      field: "id",
      sorting: true,
    },
    {
      title: "Email",
      field: "user_email",
      sorting: true,
    },
    {
      title: "Display Name",
      field: "display_name",
      sorting: true,
    },
    {
      title: <span style={{ cursor: "pointer" }}>WordPress Role</span>,
      field: "wp_role",
      sorting: false,
      render: (row: any) => {
      
        // Map roles to display strings with distinct colors
        const roleColors = {
          Administrator: "#0690c6",
          Editor: "#f66e2f",
          Author: "#4caf50",
          Contributor: "#ff9800",
          Subscriber: "#9c27b0",
          Default: "#000000", // Fallback color for unknown roles
        } as any;
      
        // Capitalize and concatenate roles
        const formattedRoles = row.roles
          .map((role: any) => {
            const capitalizedRole = role.charAt(0).toUpperCase() + role.slice(1);
            return (
              <span
                key={role}
                style={{
                  color: roleColors[capitalizedRole] || roleColors.Default,
                  marginRight: "5px",
                }}
              >
                {capitalizedRole}
              </span>
            );
          });
      
        // Render the roles as a comma-separated list
        return (
          <span style={{ cursor: "pointer" }}>
            {formattedRoles.reduce((prev: any, curr: any) => [prev, ", ", curr])}
          </span>
        );
      },
      
    },
    {
      title: "User Registered",
      field: "user_registered",
      sorting: true,
      render: (row) => (
        <span>{dateFormatted(row.user_registered, "yyyymmdd")}</span>
      ),
    },
    {
      title: "Subscriber",
      field: "active_subscriber",
      sorting: false,
      render: (row: any) => {
        if (row.active_subscriber) {
          return (
            <CheckCircle style={{ color: "#2ECF73" }} />
          )
  
        }
          return null;
 
      },
    },
    {
      title: "Status",
      field: "active",
      sorting: false,
      render: (row: any) => {
        if (row.active_subscriber){
          return null
        }
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => toggleUserAccess(row)}
          >

            {row.is_active ? (
              <ToggleOn style={{ color: "#0690c6", marginRight: "8px" }} />
            ) : (
              <ToggleOff style={{ color: "#f66e2f", marginRight: "8px" }} />
            )}
            <span>{row.is_active ? "Active" : "Inactive"}</span>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Loading loading={processing} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleMenuItemClick("Admin")}>Admin</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("User")}>User</MenuItem>
      </Menu>
      <StandardModal
        title="Activation Modal"
        open={activationModalOpen}
        onClose={() => setActivationModalOpen(false)}
        paragraphs={[
          `Are you sure you want to ${
            selectedUserData?.is_active ? "deactivate" : "activate"
          } the user with email "${selectedUserData?.user_email}"?`,
        ]}
        actions={[
          {
            title: "Cancel",
            callback: () => setActivationModalOpen(false),
          },
          {
            title: "Yes",
            callback: async () => {
              if (!selectedUserData) return;
            
              setProcessing(true);
            
              try {
                const updatedStatus = !selectedUserData.is_active;
            
                const payload = {
                  display_name: selectedUserData.display_name,
                  user_email: selectedUserData.user_email,
                  is_active: updatedStatus,
                };
            
                // API call to update user status
                await dispatch(updateBasicUserInfo(payload) as any);
            
                // Update `toggleStates` for immediate UI feedback
                setToggleStates((prev) => ({
                  ...prev,
                  [selectedUserData.id]: updatedStatus,
                }));
            
                enqueueSnackbar(
                  `User ${updatedStatus ? "activated" : "deactivated"} successfully.`,
                  { variant: "success" }
                );
            
                // Refresh the table to reflect the backend state
                await refreshTableData();
            
                // Clear modal state
                setActivationModalOpen(false);
              } catch (error) {
                enqueueSnackbar("Failed to update user status.", {
                  variant: "error",
                });
              } finally {
                setProcessing(false);
              }
            }
          },
        ]}
      />

      <div
        style={{ display: "block", textAlign: "left", margin: MARGIN_MEDIUM }}
      >
<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    <Typography variant="h5" gutterBottom>
        User Management
    </Typography>
    <FormControlLabel
        control={
            <Switch
                checked={filterAdmins}
                onChange={(e) => setFilterAdmins(e.target.checked)}
                name="filterAdmins"
                color="primary"
            />
        }
        label="Filter Admins"
    />
</div>
        <MaterialTable
          key={tableKey}
          tableRef={tableRef}
          icons={tableIcons}
          title={title}
          columns={columns}
          data={(query) =>
            new Promise((resolve, reject) => {
              (async () => {
                try {
                  const result = await fetchUsers(query);
                  resolve(result);
                } catch (error) {
                  reject(error);
                }
              })();
            })
          }
          options={{
            sorting: true,
            search: true,
            pageSize: 10,
            pageSizeOptions: [10, 25, 50, 100],
            paginationAlignment: "left",
          }}
          localization={{
						toolbar: {
						  searchPlaceholder: 'ID, Email, Display Name',
						},
					  }}
        />
      </div>
    </div>
  );
};

export default UserTable;
