import { createSelector } from 'reselect'
import { IRootState } from '../../interfaces/rootState';
import { IDocumentReferenceType } from '../../stack-shared/interfaces/model/document-reference-type.interface';
import { IDocumentReference } from '../../stack-shared/interfaces/model/document-reference.interface';
// import { IComplianceInsight } from '../../stack-shared/interfaces/model/compliance-insight.interface';
interface IComplianceInsight {
    id?: number;
    name?: string;
    text?: string;
    created_by?: number;
    updated_by?: number;
    created_at?: Date;
    updated_date?: Date;
  }
// Input Selectors
const getDocumentReferenceTypeState = (state: IRootState) => state.mainState.documentReferenceTypes
const getDocumentReferenceState = (state: IRootState) => state.mainState.document_references
const getComplianceInsightState = (state: IRootState) => state.mainState.complianceInsights

// Output Selectors
export const getDocumentReferenceTypeArray = createSelector([getDocumentReferenceTypeState], (items: any) => Object.values(items) as IDocumentReferenceType[]);
export const getDocumentReferenceArray = createSelector([getDocumentReferenceState], (items: any) => Object.values(items) as IDocumentReference[]);
export const getComplianceInsightArray = createSelector([getComplianceInsightState], (items: any) => Object.values(items) as IComplianceInsight[]);
