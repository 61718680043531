import React from "react";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import "./SearchView.css";
import { isArrayLength } from "../../constants/genericHelpers";
import { MARGIN_MEDIUM } from "../../constants/layout-enums";
import { MARGIN_SMALL } from "../Shared/layout-constants";

interface RenderComplianceInsightsProps {
  searchTerm: string;
  complianceInsightsData: any[];
}

// Utility function to validate URL
const isValidURL = (url: string): boolean => {
  try {
    const parsedUrl = new URL(url);
    return Boolean(parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:");
  } catch (error) {
    return false;
  }
};

const RenderComplianceInsights: React.FC<RenderComplianceInsightsProps> = ({
  searchTerm,
  complianceInsightsData,
}) => {
  // Find matching record
  const matchingRecord = isArrayLength(complianceInsightsData)
    ? complianceInsightsData[0]
    : complianceInsightsData.find((record) =>
        record.search_terms.toLowerCase().includes(searchTerm.toLowerCase())
      );

  if (!matchingRecord) {
    return (
      <div className="rightSide rightSideContent">
        <div className="quickFactsContentBlock">
          <div className="blockHeader">
            <h2>
              <LightbulbOutlinedIcon /> Compliance Insights
            </h2>
          </div>
          <div className="blockBody">
            <p>No matching insights found for "{searchTerm}".</p>
          </div>
        </div>
      </div>
    );
  }

  // Extract data
  const { insight_1, insight_2, insight_3, link_1, link_2, link_1_title, link_2_title, source } =
    matchingRecord;

  // Prepare insights and links
  const insights = [insight_1, insight_2, insight_3].filter(Boolean);
  const links = [
    { link: link_1, title: link_1_title },
    { link: link_2, title: link_2_title },
  ]

  const renderSource = () => {
    return (
      isValidURL(source) ? (
        <div className="sourceSec">
        <p>
          Source:{" "}
          <a href={source} target="_blank" rel="noopener noreferrer">
            <em>{source}</em>
          </a>
        </p>
      </div>
    ) : (
      <div className="sourceSec">
      <p>
        Source:{" "}
        <div style={{width: '100%', textAlign: 'center'}}>
        <span style={{color: '#F66E2F'}} >
          <em>{source}</em>
        </span>
        </div>
      </p>
    </div>
    )
    )
  }


  return (
    <div className="rightSide rightSideContent">
      <div className="quickFactsContentBlock">
        <div className="blockHeader">
          <h2>
            <LightbulbOutlinedIcon /> Compliance Insights
          </h2>
        </div>
        <div className="blockBody">
          <div className="firehose">
            <button className="firehoseBtn">{searchTerm}</button>
          </div>
          <div>
            {insights.length > 0 && (
              <div>
                <strong>Insights:</strong>
                <ul>
                  {insights.map((insight, index) => (
                    <li key={index}>{insight}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="pdfFilesList">
            {links.map(({ link, title }, index) => (
              <div key={index} className="linkContainer">
                {isValidURL(link) ? (
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {title || link} <ArrowForwardOutlinedIcon />
                  </a>
                ) : (
                  <span style={{color: '#F66E2F', marginLeft: MARGIN_SMALL}} rel="noopener noreferrer">
                  {title || link}
                </span>
                )}
              </div>
            ))}
          </div>
          {source && renderSource()}
        </div>
      </div>
    </div>
  );
};

export default RenderComplianceInsights;
