import React, { useState } from "react";
import { Tooltip, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import classNames from "classnames";

interface UpdatedFacet {
  key: string;
  value: string;
  count: number;
}

interface EntityFilterProps {
  title: string;
  tooltip: string;
  parsedFacets: UpdatedFacet[];
  activeFilters: string[];
  isCaselaw: boolean;
  entityKey: string;
  handleFilterClick: (key: string, filter: string) => void;
}

const EntityFilter: React.FC<EntityFilterProps> = ({
  title,
  tooltip,
  parsedFacets,
  activeFilters,
  isCaselaw,
  entityKey,
  handleFilterClick,
}) => {
  // Maintain visibleCounts for both guidance and caselaw separately
  const [visibleCounts, setVisibleCounts] = useState<
    Record<string, Record<string, number>>
  >({
    [entityKey]: { true: 10, false: 10 }, // Initial state for caselaw and guidance
  });

  // Convert isCaselaw to a string for key usage
  const isCaselawKey = String(isCaselaw);

  // Sort facets by count descending
  const sortedFacets = [...parsedFacets].sort((a, b) => b.count - a.count);

  // Determine visible facets for the current tab and entity
  const visibleFacets =
    sortedFacets.slice(0, visibleCounts[entityKey]?.[isCaselawKey] || 10);

  // Handle toggle for this category and tab
  const handleToggle = () => {
    setVisibleCounts((prevCounts) => ({
      ...prevCounts,
      [entityKey]: {
        ...prevCounts[entityKey],
        [isCaselawKey]:
          (prevCounts[entityKey]?.[isCaselawKey] || 10) >= sortedFacets.length
            ? 10 // Reset to initial count
            : Math.min(
                (prevCounts[entityKey]?.[isCaselawKey] || 10) + 30,
                sortedFacets.length
              ), // Increment by 30
      },
    }));
  };

  const shouldHideOptions = activeFilters.length > 0;

  return (
    <div className="filterBody">
      <div className="filterHead">
        <h2 className="filterTitle">
          {title}
          <Tooltip title={tooltip}>
            <span>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </span>
          </Tooltip>
        </h2>
      </div>
      {visibleFacets.map((facet) => {
        const isHidden = shouldHideOptions && !activeFilters.includes(facet.key);

        return (
          !isHidden && (
            <button
              key={facet.key}
              onClick={() => handleFilterClick(entityKey, facet.key)}
              className={classNames("authorityFilters", {
                active: activeFilters.includes(facet.key),
                activeAuthorityFilters: activeFilters.includes(facet.key),
              })}
            >
              {facet.value} ({facet.count})
            </button>
          )
        );
      })}
      {sortedFacets.length > 10 && (
        <button onClick={handleToggle} className="toggleFiltersButton">
          {visibleCounts[entityKey]?.[isCaselawKey] >= sortedFacets.length
            ? "Show Less"
            : `Show ${Math.min(
                30,
                sortedFacets.length - (visibleCounts[entityKey]?.[isCaselawKey] || 10)
              )} More`}
        </button>
      )}
    </div>
  );
};

export default EntityFilter;
