import React, { useState } from "react";
import { Box, Button, TextField, Chip, Typography } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  createComplianceInsight,
  updateComplianceInsight,
} from "../../actions/complianceInsightActions";
import { COLORS } from "../Shared/layout-constants";

interface UpsertComplianceInsightFormProps {
  mode: "create" | "update";
  initialValues: any;
  onCreateSuccess: (data: any) => void;
  onUpdateSuccess: (data: any) => void;
}

const UpsertComplianceInsightForm: React.FC<UpsertComplianceInsightFormProps> = ({
  mode,
  initialValues,
  onCreateSuccess,
  onUpdateSuccess,
}) => {
  const dispatch = useDispatch();
  const [searchTerms, setSearchTerms] = useState<string[]>(initialValues.search_terms || []);
  const [currentInput, setCurrentInput] = useState<string>("");

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    search_terms: Yup.array()
      .min(1, "At least one search term is required")
      .required("Search terms are required"),
    insight_1: Yup.string().required("Insight 1 is required"),
    link_1: Yup.string()
    .nullable() // Allows null values
    .notRequired() // Ensures it's optional
    .url("Link 1 must be a valid URL if provided"), // Validates only when a value is present
  link_2: Yup.string()
    .nullable()
    .notRequired()
    .url("Link 2 must be a valid URL if provided"),
  });
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentInput(event.target.value);
  };

  const handleAddChip = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && currentInput.trim() !== "") {
      event.preventDefault();
      setSearchTerms((prev) => [...prev, currentInput.trim()]);
      setCurrentInput("");
    }
  };

  const handleDeleteChip = (chipToDelete: string) => {
    setSearchTerms((prev) => prev.filter((chip) => chip !== chipToDelete));
  };

  const handleSubmit = (values: Partial<any>) => {
    const submissionValues = { ...values, search_terms: searchTerms } as any;
    if (mode === "update" && submissionValues.id) {
      dispatch(
        updateComplianceInsight(submissionValues, (data) => {
          onUpdateSuccess(data);
        })
      );
    } else {
      dispatch(
        createComplianceInsight(submissionValues, (data) => {
          onCreateSuccess(data);
        })
      );
    }
  };
  const prepareLabel = (field: string) => {
    return field
      .replace(/_/g, " ") // Replace all underscores with spaces
      .split(" ") // Split into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join back into a single string
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "80%",
          maxWidth: "500px",
          backgroundColor: "white",
          padding: "20px",
        }}
      >
<Formik
  initialValues={{
    ...initialValues,
    insight_1: initialValues.insight_1 || "",
    search_terms: searchTerms,
  }}
  validationSchema={validationSchema}
  onSubmit={async (values, { setTouched, validateForm }) => {
    // Validate all fields
    const errors = await validateForm();

    // Mark all fields as touched
    setTouched({
      name: true,
      search_terms: true,
      insight_1: true,
      insight_2: true,
      insight_3: true,
      link_1: true,
      link_2: true,
      link_1_title: true,
      link_2_title: true,
      source: true,
    });

    // If there are errors, stop submission
    if (Object.keys(errors).length > 0) {
      return;
    }

    const submissionValues = { ...values, search_terms: searchTerms };
    handleSubmit(submissionValues);
    // Continue with your submission logic (e.g., dispatching actions)
  }}
>
  {({ values, handleChange, setFieldValue, errors, touched }) => (
    <Form>
      {/* Name Field */}
      <Box sx={{ mb: 2 }}>
        <TextField
          fullWidth
          slotProps={{
            htmlInput: {
              maxLength: 280
            }
          }}
          label="Name"
          name="name"
          value={values.name || ""}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && typeof errors.name === "string" ? errors.name : ""}
        />
      </Box>

      {/* Search Terms Field */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Search Terms
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            alignItems: "center",
            border: "1px solid #ccc",
            padding: "8px",
            borderRadius: "4px",
          }}
        >
          {searchTerms.map((term, index) => (
            <Chip
              key={index}
              label={term}
              onDelete={() => {
                const updatedTerms = searchTerms.filter((chip) => chip !== term);
                setSearchTerms(updatedTerms);
                setFieldValue("search_terms", updatedTerms);
              }}
              color="primary"
            />
          ))}
          <TextField
            value={currentInput}
            onChange={handleInputChange}
            placeholder="Type and press Enter"
            variant="standard"
            onKeyDown={(event) => {
              if (event.key === "Enter" && currentInput.trim() !== "") {
                event.preventDefault();
                const updatedTerms = [...searchTerms, currentInput.trim()];
                setSearchTerms(updatedTerms);
                setFieldValue("search_terms", updatedTerms);
                setCurrentInput("");
              }
            }}
            slotProps={{
              input: {
                disableUnderline: true,
              }
            }}
            sx={{ flex: 1, minWidth: "100px" }}
          />
        </Box>
        {touched.search_terms && typeof errors.search_terms === "string" && (
          <Typography color="error" variant="body2" sx={{ mt: 1 }}>
            {errors.search_terms}
          </Typography>
        )}
      </Box>

      {/* Insight Fields */}
      {["insight_1", "insight_2", "insight_3"].map((field) => (
        <Box sx={{ mb: 2 }} key={field}>
          <TextField
            fullWidth
            slotProps={{
              htmlInput: {
                maxLength: 280
              }
            }}
            label={prepareLabel(field)}
            name={field}
            value={values[field] || ""}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            multiline
            minRows={3}
            error={touched[field] && Boolean(errors[field])}
            helperText={touched[field] && typeof errors[field] === "string" ? errors[field] : ""}
          />
        </Box>
      ))}

      {/* Link and Source Fields */}
      {["link_1", "link_2", "link_1_title", "link_2_title", "source"].map((field) => (
        <Box sx={{ mb: 2 }} key={field}>
          <TextField
            fullWidth
            slotProps={{
              htmlInput: {
                maxLength: 280
              }
            }}
            label={prepareLabel(field)}
            name={field}
            error={touched[field] && Boolean(errors[field])}
            value={values[field] || ""}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
          />
        </Box>
      ))}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        style={{
          textTransform: "none",
          marginTop: "20px",
          padding: "10px 0",
          fontSize: "16px",
          backgroundColor: COLORS.BRAND_PRIMARY,
        }}
      >
        Submit
      </Button>
    </Form>
  )}
</Formik>




      </div>
    </div>
  );
};

export default UpsertComplianceInsightForm;
