
import { Box, Divider, Drawer, List, ListItem, ListItemIcon, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AIResultIcon from '@mui/icons-material/Psychology';
import { MonitorHeart } from "@mui/icons-material";
import SolrIcon from '@mui/icons-material/WbSunny';
import PeopleIcon from '@mui/icons-material/People';
import DocumentIcon from '@mui/icons-material/DocumentScanner'
import { LightbulbCircle } from "@mui/icons-material";
import DocumentCaseLawIcon from '@mui/icons-material/AccountBalanceOutlined'
import { iconStyle } from "../Shared/MaterialTableShared";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { APP_BAR_HEIGHT, FOOTER_HEIGHT } from "../../constants/layout-enums";
import { MARGIN_LARGE } from "../Shared/layout-constants";


const AdminContainer = (props: any) => {
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const [innerHeight, setInnerHeight] = useState<number>(window.innerHeight);

	const location = useLocation();
	const [lastPart, setLastPart] = useState<string | null>(null);
  
	useEffect(() => {
	  // Extract the last part of the URL
	  const pathSegments = location.pathname.split('/').filter(Boolean);
	  const lastSegment = pathSegments.pop();
	  setLastPart(lastSegment ?? null);
	}, [location]);
	
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const navigate = useNavigate();
	const [open, setOpen] = useState<false | true>(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		const handleResize = () => {
			setInnerHeight(window.innerHeight);
		};

		// Update height on window resize
		window.addEventListener("resize", handleResize);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const renderTab = (key: string, label: string) => {
		return(
			lastPart === key ? <b>{label}</b> : <span>{label}</span>
		)
	}


	const drawer = (
		<div style={{minHeight:  innerHeight - APP_BAR_HEIGHT - FOOTER_HEIGHT, width: 250}} >

			<List>
				<ListItem style={{cursor: 'pointer'}} key={'dashboard'} component="li"
					onClick={(): void => {
						navigate('/admin/dashboard')
					}}
				>
					<ListItemIcon style={{ minWidth: 42 }}>
						<DashboardIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={renderTab('dashboard', 'Dashboard')} />
				</ListItem>

				<ListItem style={{cursor: 'pointer'}} key={'users'} component="li"
					onClick={(): void => {
						navigate('/admin/users')
					}}
				>
					<ListItemIcon style={{ minWidth: 42 }}>
						<PeopleIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={renderTab('users', 'User Management')} />
				</ListItem>

				<ListItem style={{cursor: 'pointer'}} key={'guidance-documents'} component="li"
					onClick={(): void => {
						navigate('/admin/guidance-documents')
					}}
				>
					<ListItemIcon style={{ minWidth: 42 }}>
						<DocumentIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={renderTab('guidance-documents', 'Guidance Documents')} />
				</ListItem>

				<ListItem style={{cursor: 'pointer'}} key={'case-law'} component="li"
					onClick={(): void => {
						navigate('/admin/case-law')
					}}
				>
					<ListItemIcon style={{ minWidth: 42 }}>
						<DocumentCaseLawIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={renderTab('case-law', 'Case Law')} />
				</ListItem>

				<ListItem style={{cursor: 'pointer'}} key={'compliance-insights'} component="li"
					onClick={(): void => {
						navigate('/admin/compliance-insights')
					}}
				>
					<ListItemIcon style={{ minWidth: 42 }}>
						<LightbulbCircle style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={renderTab('compliance-insights', 'Compliance Insights')} />
				</ListItem>

				<ListItem style={{cursor: 'pointer'}} key={'entity-extraction'} component="li"
					onClick={(): void => {
						navigate('/admin/entity-extraction')
					}}
				>
					<ListItemIcon style={{ minWidth: 42 }}>
						<DocumentCaseLawIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={renderTab('entity-extraction', 'Entity Extraction')} />
				</ListItem>

				<ListItem style={{cursor: 'pointer'}} key={'ai-results'} component="li"
					onClick={(): void => {
						navigate('/admin/ai-results')
					}}
				>
					<ListItemIcon style={{ minWidth: 42 }}>
						<AIResultIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={renderTab('ai-results', 'AI Results')} />
				</ListItem>
				<ListItem style={{cursor: 'pointer'}} key={'health'} component="li"
					onClick={(): void => {
						navigate('/admin/health')
					}}
				>
					<ListItemIcon style={{ minWidth: 42 }}>
						<MonitorHeart style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={renderTab('health', 'Health')} />
				</ListItem>
				<a style={{textDecoration: 'none', color: 'inherit'}} target="_blank" href={process.env.REACT_APP_SOLR_ADMIN_URL}>
				<ListItem style={{cursor: 'pointer'}} key={'solr-dashbaord'} component="li"

				>
					<ListItemIcon style={{ minWidth: 42 }}>
						<SolrIcon style={iconStyle} />
					</ListItemIcon>
					<ListItemText primary={'Solr Dashboard'} />
				</ListItem>
				</a>
			</List>
		</div>

	);

	function terms() {
		props.history.push("/termsAndConditions");
	}

	const menuId = "primary-search-account-menu";

	return (

        <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ width: '250px', backgroundColor: '#ddd' }}>
                {drawer}
            </div>
            <div style={{ flexGrow: 1, backgroundColor: '#eee' }}>
			<Outlet />
            </div>
        </div>

		// <div>
		// 	<Box>
		// 		<Drawer
		// 			PaperProps={{ sx: { backgroundColor: COLORS.BRAND_PRIMARY_DARK, color: COLORS.WHITE_PAPER } }}
		// 			container={container}
		// 			variant="temporary"
		// 			open={mobileOpen}
		// 			onClose={handleDrawerToggle}
		// 			ModalProps={{
		// 				keepMounted: true, // Better open performance on mobile.
		// 			}}
		// 			sx={{
		// 				display: { xs: 'block', sm: 'none' },
		// 				'& .MuiDrawer-paper': { boxSizing: 'border-box', width: 250 },
		// 			}}
		// 		>
		// 			{drawer}
		// 		</Drawer>
		// 		<Drawer
		// 			PaperProps={{ sx: { backgroundColor: COLORS.BRAND_PRIMARY_DARK, color: COLORS.WHITE_PAPER } }}
		// 			variant="permanent"
		// 			sx={{
		// 				display: { xs: 'none', sm: 'block' },
		// 				'& .MuiDrawer-paper': { boxSizing: 'border-box', width: 250 },
		// 			}}
		// 			open
		// 		>
		// 			{drawer}
		// 		</Drawer>
		// 	</Box>
		// 	<div style={{ marginLeft: 250 }}>
		// 		<Outlet />
		// 	</div>

		// </div>
	);
};

export default AdminContainer;
