import { isString } from "lodash";

export function GetFriendlyError(error: any): string {
  try {
    if (error?.response?.data?.message) {
      return error.response.data.message;
    }
    if (isString(error)) {
      return error as string;
    }
  } catch (e) {
    console.log(e);
  }
  return "Error: Unknown Error";
}

export function capitalizeFirstLetter(str: string) {
  if (!str) return ''; // Handle empty strings or null/undefined
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const safeBool = (
  input: string | boolean,
  defaultValue = false,
): boolean => {
  try {
    let newInput = input;
    if (typeof input === "string") {
      newInput = input.toLowerCase();
    }
    if (newInput === "true" || newInput === true) {
      return true;
    }
    if (newInput === "false" || newInput === false) {
      return false;
    }
    return defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

export const boolToString = (val: any, trueVal: string, falseVal: string) => {
  let boolval = false;
  if (typeof val === "boolean") {
    boolval = val;
  } else {
    if (typeof val === "string") {
      if (val.toLowerCase() === "true") {
        boolval = true;
      }
    }
  }
  if (boolval === true) {
    return trueVal;
  }
  return falseVal;
};

export const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const getUrlParam = (param: string) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(param);
};

export const roundNumberFixed2 = (num: any): number => {
	try {
		return Math.round((Number(num) + Number.EPSILON) * 100) / 100;
	}
	catch {
		return 0
	}
}

export const getFileExtension = (fileNameRaw: string): string => {
	const fileName = fileNameRaw.toLowerCase();
	const match = fileName.match(/[0-9a-z]+$/);
	if (!match || !match[0]) {
		console.log('Not a valid filename');
		throw new Error('Not a valid filename');
	}
	return match[0];
};
