import React, { useState } from "react";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Skeleton from "@mui/material/Skeleton";
import { getDocumentAIStateKeyFlexible } from "../../constants";
import { isArrayLength } from "../../constants/genericHelpers";
import Modal from "@mui/material/Modal";
import { searchPlugin } from "@react-pdf-viewer/search";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import PdfViewer from "../PdfViewer/PdfViewer";
import { useLocation } from "react-router-dom";
import StreamedHTMLRenderer from "../Shared/StreamedHtmlRenderer";
import { useSelector } from "react-redux";
import { IRootState } from "../../interfaces/rootState";
import { useSnackbar } from "notistack";

interface ISearchViewCoPilot {
  documentType: string;
  aiFinalResult: any;
  aiFetching: boolean;
  streamText: any;
  aiText: string;
  updateAIFeedback: any;
  feedbackAttributeName: string;
  renderSkeleton: any;
  searchTerm: string;
}


const includesIDontKnow = (text: any) => {
  const normalize = (str: any) =>
    str
      .toLowerCase()
      .replace(/'/g, "") // Remove apostrophes
      .replace(/\s+/g, "") // Remove spaces and newlines
      .trim(); // Ensure no leading/trailing whitespace
  return normalize(text).includes(normalize("I don't know"));
};


const renderSourceNodes = (
  source_nodes: any,
  setModalOpen: any,
  setNodePath: any
) => {
  const uniqueNodes = [] as any[];
  source_nodes.forEach((node: any) => {
    const existing = uniqueNodes.find(
      (x) => x?.source_url === node?.source_url
    );
    if (!existing) {
      uniqueNodes.push(node);
    }
  });
  return uniqueNodes.map((node: any, index: number) => {
    return (
      <span
        key={index}
        onClick={() => {
          setModalOpen(true);
          setNodePath(node?.signed_download_url);
        }}
      >
        {node?.friendly_title} <ArrowForwardOutlinedIcon />
      </span>
    );
  });
};

const SearchViewCoPilot: React.FC<any> = (props: ISearchViewCoPilot) => {

    const { enqueueSnackbar } = useSnackbar();

  const { aiFinalResult, aiFetching, documentType, updateAIFeedback, searchTerm } =
    props;

  const aiResultStateRaw = useSelector((state: IRootState) => state.mainState.aiResult);

  const aiResultState = aiResultStateRaw?.[getDocumentAIStateKeyFlexible(documentType)]

  const streamText = aiResultState?.text

  const aiResultObj = aiFinalResult?.[documentType]

  let displayText = aiResultObj?.data?.text || streamText

  if (includesIDontKnow(displayText)){
    displayText = "OSHAWISE Copilot’s body of knowledge is limited to OSHAWISE related topics, regulations, guidance and case law documents. The AI is having trouble generating a response to this question. We’ve logged this query to evaluate relevance and continue to improve the AI training."
  }

  const searchPluginInstance = searchPlugin();

  const location = useLocation();
  const currentPath = location?.pathname?.split("/");
  const currentRegulationType = currentPath?.[currentPath.length - 1];

  const [selectedFeedback, setSelectedFeedback] = useState<string | null>(null);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const [urlNodePath, setNodePath] = useState<string | null>(null);

  const renderModal = () => (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className="filterModal"
    >
      <div className="filterModalContent">
        <div className="filterModalHead">
          <div className="filterModalTitle">
            <h2
              id="modal-title"
              role="button"
              className="backToResults"
              onClick={() => setModalOpen(false)}
            >
              {" "}
              Back to results
            </h2>
            <h2 id="modal-title">{searchTerm}</h2>
            <p id="modal-description">
              You can view the content for this search in the viewer below.
            </p>
          </div>
          <div
            className="filterModalClose"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="filterModalBody">
          {urlNodePath ? (
            <PdfViewer fileUrl={urlNodePath} searchTerm={searchTerm} />
          ) : null}
        </div>
      </div>
    </Modal>
  );

  const handleFeedbackClick = (feedback: "thumbs_up" | "thumbs_down") => {
    setSelectedFeedback(feedback);

    let feedbackMessage = "";
    if (feedback === "thumbs_up"){
      feedbackMessage = "Thanks for your feedback! I'm glad I could help."
    }
    else{
      feedbackMessage = "Thanks for your feedback! I'll try to do better next time."
    }
    enqueueSnackbar(feedbackMessage, { variant: "info" });
    updateAIFeedback({
      id: aiResultObj?.id,
      user_feedback: feedback === "thumbs_up" ? "THUMBS_UP" : "THUMBS_DOWN",
    });
  };

  const getCasePilotTitle = () => {
    switch (currentRegulationType) {
      case "regulations":
        return "Guidance";
      case "guidance":
        return "Guidance";
      case "case-law":
      case "caselaw":
      case "case_law":
        return "Case Law";
    }
  }

  return (
    <div className="rightSide rightSideContent">
      <div className="rightSideContentBlock">
        <div className="blockHeader">
          <h2>
            <AutoAwesomeOutlinedIcon sx={{ color: "white" }} />{" "}
            {`${getCasePilotTitle()} Copilot`}
          </h2>
        </div>
        <div className="blockBody">
          {(aiFetching && !streamText) ? (
            <>
              <Skeleton
                variant="text"
                width="80%"
                height={30}
                sx={{ bgcolor: "#f3f3f39e" }}
              />
              <Skeleton
                variant="text"
                width="100%"
                height={30}
                sx={{ bgcolor: "#f3f3f39e" }}
              />
              <Skeleton
                variant="text"
                width="80%"
                height={30}
                sx={{ bgcolor: "#f3f3f39e" }}
              />
              <Skeleton
                variant="text"
                width="100%"
                height={30}
                sx={{ bgcolor: "#f3f3f39e" }}
              />
            </>
          ) : (
            <>
              <p><StreamedHTMLRenderer streamedText={displayText} /></p>
              <div className="pdfFilesList">
                {isArrayLength(aiResultObj?.data?.source_nodes)
                  ? renderSourceNodes(
                    aiResultObj?.data?.source_nodes,
                    setModalOpen,
                    setNodePath
                  )
                  : null}
              </div>
            </>
          )}
          {aiResultObj?.id && !aiFetching ? (
            <div className="likeDislikeSec">
              <button
                role="button"
                onClick={() => handleFeedbackClick("thumbs_up")}
              >
                {selectedFeedback === "thumbs_up" ? (
                  <ThumbUpAltIcon />
                ) : (
                  <ThumbUpAltOutlinedIcon />
                )}
                This was helpful
              </button>
              <button
                role="button"
                onClick={() => handleFeedbackClick("thumbs_down")}
              >
                {selectedFeedback === "thumbs_down" ? (
                  <ThumbDownAltIcon />
                ) : (
                  <ThumbDownOffAltOutlinedIcon />
                )}
                This wasn’t helpful
              </button>
            </div>
          ) : null}
          {renderModal()}
        </div>
      </div>
    </div>
  );
};

export default SearchViewCoPilot;
