import React from "react";
import SearchViewFilters from "./SearchViewFilters";
import SearchViewResultsPanel from "./SearchViewResultsPanel";
import "./SearchView.css";
import RenderComplianceInsights from "./RenderComplianceInsights";
import {
  GUIDANCE_TYPE_OPTIONS,
  PUBLICATION_TYPE,
} from "../../constants/search-view.constants";

interface RegulationWithCount {
  reg: string; // Regulation number
  title: string; // Regulation title
  count: number; // Count of occurrences
}

interface FilterItem {
  label: string; // Label for the filter
  count: number; // Count of occurrences
  isSubFilter?: boolean; // Optional: Whether it is a sub-filter
}
interface SearchViewContentProps {
  solrResultsItems: any;
  totalItems: number;
  searchTerm: string;
  parsedSearchKeyword: string;
  renderCoPilot: React.ReactNode;
  guidanceHighlightingData: any[];
  caselawHighlightingData: any[];
  complianceInsightsData: any[];
  itemsPerPage: number;
  setItemsPerPage: (value: number) => void;
  currentPage: number;
  regulationFilter: any;
  setRegulationFilter: (value: any) => void;
  guidanceFilter: any;
  facetedNavGuidanceFilters: any;
  setGuidanceFilter: (value: any) => void;
  caselawFilter: any;
  setCaselawFilter: (value: any) => void;
  setCurrentPage: (page: number) => void;
  facetedNavFilters: RegulationWithCount[]; // Updated type to expect an array of RegulationWithCount
}

const SearchViewContent: React.FC<SearchViewContentProps> = ({
  solrResultsItems,
  totalItems,
  searchTerm,
  renderCoPilot,
  itemsPerPage,
  guidanceHighlightingData,
  caselawHighlightingData,
  parsedSearchKeyword,
  setItemsPerPage,
  currentPage,
  setCurrentPage,
  facetedNavFilters,
  complianceInsightsData,
  facetedNavGuidanceFilters,
  regulationFilter,
  setRegulationFilter,
  guidanceFilter,
  setGuidanceFilter,
  caselawFilter,
  setCaselawFilter,
}) => {
  const selectedGuidanceType =
    GUIDANCE_TYPE_OPTIONS.find(
      ({ key }) => key === guidanceFilter?.guidance_type?.[0]
    )?.value || "";

  const selectedPublicationType =
    guidanceFilter?.guidance_type?.[0] === "Publication"
      ? PUBLICATION_TYPE.find(
          ({ key }) => key === guidanceFilter?.publication_type?.[0]
        )?.value || ""
      : "";

  return (
    <div className="searchViewSkeleton">
      <SearchViewFilters
        facetedNavFilters={facetedNavFilters as any}
        facetedNavGuidanceFilters={facetedNavGuidanceFilters}
        regulationFilter={regulationFilter}
        setRegulationFilter={setRegulationFilter}
        guidanceFilter={guidanceFilter}
        setGuidanceFilter={setGuidanceFilter}
        caselawFilter={caselawFilter}
        setCaselawFilter={setCaselawFilter}
      />
      <div className="mainContent">
        {renderCoPilot}
        <SearchViewResultsPanel
          searchTerm={searchTerm}
          totalItems={totalItems}
          parsedSearchKeyword={parsedSearchKeyword}
          solrResultsItems={solrResultsItems}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          guidanceHighlightingData={guidanceHighlightingData}
          caselawHighlightingData={caselawHighlightingData}
          searchTagTitle={
            selectedGuidanceType
              ? `${selectedGuidanceType}${
                  selectedPublicationType ? ` - ${selectedPublicationType}` : ""
                }`
              : ""
          }
        />
      </div>
      <RenderComplianceInsights
        searchTerm={parsedSearchKeyword}
        complianceInsightsData={complianceInsightsData}
        
      />
    </div>
  );
};

export default SearchViewContent;
