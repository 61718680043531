import React, { useEffect, useState } from "react";
import { Button, TextField, Grid, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, updateBasicUserInfo, updateMyUserInfo } from "../../actions/userActions";
import { REACT_APP_WP_DOMAIN } from "../../constants";
import { IRootState } from "../../interfaces/rootState";
import Checkboxes from "../Checkboxes/Checkboxes";
import { UserEditField } from "./UserEditField";
import "./UserEdit.css";
import { innerContainerHeight, MARGIN_LARGE, MARGIN_XLARGE } from "../Shared/layout-constants";
import { useSnackbar } from "notistack";

const UserEdit: React.FC = () => {
  const loggedUser = useSelector((state: IRootState) => state.mainState.loggedUser);
  const [userBasic, setUserBasic] = useState(loggedUser);
  const [userAdvanced, setUserAdvanced] = useState(null as any);
  const [miningMethod, setMiningMethod] = useState([]);
  const [commodity, setCommodity] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const buttonStyle = {
    borderRadius: "5px",
    width: "72%",
    padding: "10px 16px",
    color: "#ffffff",
    textTransform: "none",
    boxShadow: "0px 1px 5px 0px #0000001F",
    "&.Mui-disabled": {
      background: "#eaeaea",
      color: "#c0c0c0",
    },
  }

  const dispatch = useDispatch();

  const save = (values: any) => {
    const payload = {
      data: [
        { key: "Title/Role", value: values.Role },
        { key: "Company/Group", value: values.Company },
      ],
    };
    commodity.forEach((comm: any) => payload.data.push({ key: "Mining Commodities", value: comm }));
    miningMethod.forEach((meth: any) => payload.data.push({ key: "Mining Methods", value: meth }));

    const basicUserPayload = {
      display_name: values?.Name,
      user_email: values.Email,
    };

    dispatch(
      updateMyUserInfo(payload, () => {
        dispatch(updateBasicUserInfo(basicUserPayload, () => {
          enqueueSnackbar("User info updated", { variant: "success" });
        }) as any);
      }) as any
    );
  };

  useEffect(() => {
    if (!window.localStorage.jwt || window.localStorage.jwt === "undefined") {
      window.localStorage.clear();
    } else {
      dispatch(
        getUserInfo(window.localStorage.jwt, (data: any) => {
          setUserAdvanced(data);
        }) as any
      );
    }
  }, [dispatch]);

  let company = "Company";
  let role = "Role";

  if (userAdvanced?.results?.length) {
    company = userAdvanced.results.find((x: any) => x.key === "Company/Group")?.value || company;
    role = userAdvanced.results.find((x: any) => x.key === "Title/Role")?.value || role;
  }

  return (
    <div className="UserEditContainer" style={{minHeight: innerContainerHeight}}>
      <div className="spacing" />
      <Grid container spacing={2}>
        {/* Left Column */}
        <Grid item xs={12} md={6}>
          <Formik
            enableReinitialize
            initialValues={{
              Name: userBasic.name,
              Email: userBasic.email,
              Role: role,
              Company: company,
            }}
            onSubmit={(values) => save(values)}
          >
            {({ values }) => (
              <Form>
                <h2>Profile</h2>
                <span>Name</span>
                <div className="userFields">
                  <Field
                    disabled={false}
                    name="Name"
                    label="Name"
                    component={UserEditField}
                  />
                </div>
                <span>Email</span>
                <div className="userFields">
                  <Field
                    disabled={false}
                    name="Email"
                    label="Email"
                    component={UserEditField}
                  />
                </div>
                <span>Company/Group</span>
                <div className="userFields">
                  <Field
                    disabled={false}
                    name="Company"
                    label="Company/Group"
                    component={UserEditField}
                  />
                </div>
                <span>Title/Role</span>
                <div className="userFields">
                  <Field
                    disabled={false}
                    name="Role"
                    label="Title/Role"
                    component={UserEditField}
                  />
                </div>
                <Checkboxes
                  updateMining={(value: any) => setMiningMethod(value)}
                  updateCommodity={(value: any) => setCommodity(value)}
                  userAdvanced={userAdvanced}
                />
                <Button
                  type="submit"
                  sx={{
                    ...buttonStyle,
                    backgroundColor: "#F66E2F",
                  }}
                >
                  Save Changes
                </Button>
              </Form>
            )}
          </Formik>
        </Grid>

        {/* Right Column */}
        <Grid item xs={12} md={6}>
          <h2>Billing</h2>
          <div className="rightUserFields">
            <TextField
              disabled
              variant="outlined"
              label="User ID"
              value={userBasic.id}
            />
          </div>
          <div className="rightUserFields">
            <TextField
              disabled
              variant="outlined"
              label="Account Role"
              value={(loggedUser?.roles ?? [])
                .map((role: any) => role.charAt(0).toUpperCase() + role.slice(1))
                .join(", ")}
            />
          </div>
          <div style={{ marginTop: MARGIN_LARGE }}>
            <Button
              sx={{
                ...buttonStyle,
                backgroundColor: "#5CA8C4",
              }}
              href={REACT_APP_WP_DOMAIN + "/my-account/"}
            >
              Manage Subscription
            </Button>
          </div>

          <div className="userInfoContainer" style={{ marginTop: MARGIN_XLARGE }}>
            <Typography className="userFirstText">Happy Searching!</Typography>
            <Typography className="userSecondText">
              Please Contact us at:
            </Typography>
            <Typography className="userSecondText">
              Phone: (202) 262-4081
            </Typography>
            <Typography className="userSecondText">
              Email: mark@oshawise.com
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserEdit;
