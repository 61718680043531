
import React, { useEffect } from "react";
import { MARGIN_MEDIUM } from "../Shared/layout-constants";
import { useDispatch } from "react-redux";
import { getAdminDashboardData } from "../../actions/adminActions";
import AiResultGraph from "../Admin/AIResultGraph";
import DocumentSummaryTable from "./DocumentSummaryData";
import { Typography } from "@mui/material";
import TopSearchesTable from "./TopSearchesData";

const AdminDashboard: React.FC = (props: any) => { 

    const dispatch = useDispatch();

    const [summaryData, setSummaryData] = React.useState<any>([]);
    useEffect(() => {
      dispatch(getAdminDashboardData({}, (data) => {
        data = data.filter((d: any) => d.documentTypeName !== null);
          setSummaryData(data);
      }))
      }, []);

    return <div style={{margin: MARGIN_MEDIUM, textAlign: 'left'}}>
      {summaryData.length > 0 && <>
        <Typography variant="h5" gutterBottom>
       Document Summary Data
      </Typography>
      <DocumentSummaryTable data={summaryData} />
      </>}

        <AiResultGraph />
        <TopSearchesTable />
    </div>

}
export default AdminDashboard