export const actionIds = {
    // Fetch App Data
    GET_PUBLIC_DATA: "GET_PUBLIC_DATA",
    SOLR_SEARCH_FETCH: "SOLR_SEARCH_FETCH",
    CASE_LAW_SEARCH_FETCH: "CASE_LAW_SEARCH_FETCH",
    SOLR_REGULATIONS_FETCH: "SOLR_REGULATIONS_FETCH",
    SOLR_QUICK_FETCH: "SOLR_QUICK_FETCH",
    SOLR_RELATED_FETCH: "SOLR_RELATED_FETCH",
    SOLR_SEARCH_SUCCESS: "SOLR_SEARCH_SUCCESS",
    CASE_LAW_SEARCH_SUCCESS: "CASE_LAW_SEARCH_SUCCESS",
    SOLR_REGULATIONS_SUCCESS: "SOLR_REGULATIONS_SUCCESS",
    SOLR_QUICK_SUCCESS: "SOLR_QUICK_SUCCESS",
    SOLR_RELATED_SUCCESS: "SOLR_RELATED_SUCCESS",
    AI_SEARCH: "AI_SEARCH",
    SET_AI_RESULT: "SET_AI_RESULT",
    CLEAR_AI_RESULT: "CLEAR_AI_RESULT",
    CLEAR_AI_RESULT_CASE_LAW: "CLEAR_AI_RESULT_CASE_LAW",
    MERGE_AI_RESULT: "MERGE_AI_RESULT",
    UPDATE_AI_FEEDBACK: "UPDATE_AI_FEEDBACK",
    USER_ACTION: "USER_ACTION",
    UPDATE_MY_USER_ACTION: "UPDATE_MY_USER_ACTION",
    UPDATE_BASIC_USER_ACTION: "UPDATE_BASIC_USER_ACTION",
    GET_USER_ACTION: "GET_USER_ACTION",
    GET_USER_SUCCESS: "GET_USER_SUCCESS",
    SIGNIN_ACTION: "SIGNIN_ACTION",
    SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
    REFRESH_TOKEN_ACTION: "REFRESH_TOKEN_ACTION",
    PASSWORD_SET_ACTION: "PASSWORD_SET_ACTION",
    PASSWORD_RESET_ACTION: "PASSWORD_RESET_ACTION",
    LOGIN_ERROR: "LOGIN_ERROR",
    LOGOUT: "LOGOUT",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    PASSWORD_ERROR: "PASSWORD_ERROR",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    INFO: "INFO",
    SHOW_ERROR: "SHOW_ERROR",
    SHOW_INFO: "SHOW_INFO",
    SHOW_SUCCESS: "SHOW_SUCCESS",
    START_LOADING: "START_LOADING",
    STOP_LOADING: "STOP_LOADING",
    START_LOADING_SUCCESS: "START_LOADING_SUCCESS",
    STOP_LOADING_SUCCESS: "STOP_LOADING_SUCCESS",
    CLEAR_SEARCH_CACHE: "CLEAR_SEARCH_CACHE",
    GET_DOCUMENT_REFERENCES: "GET_DOCUMENT_REFERENCES",
    CREATE_DOCUMENT_REFERENCE: 'CREATE_DOCUMENT_REFERENCE',
    UPDATE_DOCUMENT_REFERENCE: 'UPDATE_DOCUMENT_REFERENCE',
    DOWNLOAD_UPLOAD_S3: 'DOWNLOAD_UPLOAD_S3',
    UPDATE_DOCUMENT_REFERENCE_TYPE: 'UPDATE_DOCUMENT_REFERENCE_TYPE',
    VECTORIZE_DOCUMENT_REFERENCE: "VECTORIZE_DOCUMENT_REFERENCE",
    DELETE_DOCUMENT_REFERENCE: "DELETE_DOCUMENT_REFERENCE",
    GET_ADMIN_DASHBOARD_DATA: "GET_ADMIN_DASHBOARD_DATA",
    INDEX_DOCUMENT: "INDEX_DOCUMENT",
    FULL_INDEX: "FULL_INDEX",
    GET_SYSTEM_SETTINGS: "GET_SYSTEM_SETTINGS",
    UPDATE_SYSTEM_SETTINGS: "UPDATE_SYSTEM_SETTINGS",
    SEARCH_REGULATIONS: "SEARCH_REGULATIONS",
    SEARCH_GUIDANCE: "SEARCH_GUIDANCE",
    UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
    GET_S3_UPLOAD_URL: "GET_S3_UPLOAD_URL",
    GET_S3_UPLOAD_URL_SUCCESS: "GET_S3_UPLOAD_URL_SUCCESS",
    S3_PRESIGNED_URL_UPLOAD: "S3_PRESIGNED_URL_UPLOAD",
    INGEST_DOCUMENT: "INGEST_DOCUMENT",
    SOLR_GET_DOCUMENT_BY_ID: "SOLR_GET_DOCUMENT_BY_ID",
    MILVUS_GET_BY_ID: "MILVUS_GET_BY_ID",
    GET_COMPLIANCE_INSIGHTS: "GET_COMPLIANCE_INSIGHTS",
    SEARCH_COMPLIANCE_INSIGHTS: "SEARCH_COMPLIANCE_INSIGHTS",
    UPDATE_COMPLIANCE_INSIGHT: "UPDATE_COMPLIANCE_INSIGHT",
    CREATE_COMPLIANCE_INSIGHT: "CREATE_COMPLIANCE_INSIGHT",
    DELETE_COMPLIANCE_INSIGHT: "DELETE_COMPLIANCE_INSIGHT",
    ADMIN_MESSAGE: "ADMIN_MESSAGE",
    CLEAR_ADMIN_MESSAGES: "CLEAR_ADMIN_MESSAGES",
    TOP_SEARCHES: "TOP_SEARCHES",
};

/**
 * type: type of action
 * payload: payload of action
 * normalized (optional): indicate a action must be normalized before handle it (add)
 * removeKeys (optional): indicate a action must be normalized before handle it (remove)
 * setkeys (optional): indicate a action must be normalized before handle it (update)
 */
export interface BaseAction {
    type: string;
    payload: any;
    onSuccess: Function | null
    onFail: Function | null
}
