import React from "react";
import { useMediaQuery, Drawer, IconButton, Tooltip } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import "./SearchView.css";
import {
  GUIDANCE_TYPE_OPTIONS,
  PUBLICATION_TYPE,
} from "../../constants/search-view.constants";
import {
  isArrayLength,
  sortArrayByProperty,
} from "../../constants/genericHelpers";
import InfoIcon from "@mui/icons-material/Info";
import FacetedNavFilter from "./FacetedNavFilter";
import EntityFilter from "./EntityFilter";
// Define the hierarchy for sorting
const hierarchy: string[] = [
  "Statute",
  "Regulation",
  "ComplianceDirective",
  "Interpretation",
  "Publication",
  "IndustryGuidance",
];

// Original cleanFacet function
function cleanFacet(str: string): string {
  str = str.replace(/ /g, "_");
  str = str.replace(/\./g, "replaceperiod");
  str = str.replace(/\&/g, "replaceampersand");
  return str.toLowerCase();
}

// Define the type for facets
interface Facet {
  title: string;
  count: number;
}

// Define the type for the updated facets array
interface UpdatedFacet {
  key: string;
  value: string;
  count: number;
}

// Helper function
function parseFacets(facets: Facet[]): UpdatedFacet[] {
  return facets.map((facet: Facet) => {
    const formattedTitle = facet.title
      .replace(/_/g, " ")
      .replace(/replaceperiod/g, ".")
      .replace(/replaceampersand/g, "&")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return {
      key: cleanFacet(facet.title),
      value: formattedTitle,
      count: facet.count,
    };
  });
}
// Define the type for the filter items
interface FilterItem {
  key: string;
  value: string;
}

// Helper function to sort by hierarchy
function sortByHierarchy(
  selectedGuidanceTypeFilter: FilterItem[]
): FilterItem[] {
  return selectedGuidanceTypeFilter.sort((a, b) => {
    const indexA = hierarchy.indexOf(a.key);
    const indexB = hierarchy.indexOf(b.key);

    // If key not found in the hierarchy, place it at the end
    const rankA = indexA !== -1 ? indexA : hierarchy.length;
    const rankB = indexB !== -1 ? indexB : hierarchy.length;

    return rankA - rankB;
  });
}

// Helper function to merge, match, and filter counts
function mergeAndFilterCounts(
  options: { key: string; value: string }[],
  filterData: { title: string; count: number }[]
) {
  // Convert all keys and titles to lowercase for matching
  const lowerCasedFilterData = filterData.map((item) => ({
    ...item,
    title: item.title.toLowerCase(),
  }));

  return options
    .map((option) => {
      const matchedItem = lowerCasedFilterData.find(
        (item) => item.title === option.key.toLowerCase()
      );
      return {
        ...option,
        count: matchedItem ? matchedItem.count : 0, // Attach count if found, else 0
      };
    })
    .filter((item) => item.count > 0); // Filter out items with count 0
}

// Define the type for individual filters
interface FilterItem {
  reg: string; // Regulation number or filter type
  title: string; // Title or filter label
  count?: number; // Count of occurrences (optional for Authority Level filters)
  children?: FilterItem[]; // Child options for hierarchical filters
}

// Define the props for the component
interface Props {
  facetedNavFilters: FilterItem[]; // Array of filters for Regulations
  facetedNavGuidanceFilters: any;
  guidanceFilter: any;
  setGuidanceFilter: (value: any) => void;
  caselawFilter: any;
  setCaselawFilter: (value: any) => void;
  regulationFilter: any;
  setRegulationFilter: (value: any) => void;
}

export default function SearchViewFilters({
  facetedNavFilters,
  facetedNavGuidanceFilters,
  regulationFilter,
  setRegulationFilter,
  guidanceFilter,
  setGuidanceFilter,
  caselawFilter,
  setCaselawFilter,
}: Props) {
  const selectedGuidanceType = isArrayLength(guidanceFilter?.guidance_type)
    ? guidanceFilter?.guidance_type[0] === "Publication" &&
      !isArrayLength(guidanceFilter?.publication_type)
      ? null
      : guidanceFilter?.guidance_type[0]
    : null;

  const isMobile = useMediaQuery("(max-width:1024px)");
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const location = useLocation();

  const isGuidance = location?.pathname?.includes("guidance");
  const isCaselaw = location?.pathname?.includes("case-law");
  const isRegulations = location?.pathname?.includes("regulations");

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleRegulationFilterClick = (filter: string) => {
    setRegulationFilter((prevFilter: any) => {
      const currentFilters = prevFilter?.reg_num || [];
      const isFilterActive = currentFilters.includes(filter);

      // Toggle the filter: remove it if active, otherwise set it as the only active filter
      return {
        reg_num: isFilterActive ? [] : [filter], // Clear the filter if already selected, or set the new one
      };
    });
  };

  const handleAuthorityFilterClick = (filter: string, isChild?: boolean) => {
    setGuidanceFilter((prevFilter: any) => {
      const guidanceType = prevFilter?.guidance_type || [];
      const publicationType = prevFilter?.publication_type || [];
      const entityPerson = prevFilter?.entity_person || [];
      const entityLocation = prevFilter?.entity_location || [];
      const entityOrganization = prevFilter?.entity_organization || [];

      if (isChild) {
        // If a child filter is clicked
        const parentFilter = "Publication"; // The parent of the child options
        const isChildActive = publicationType.includes(filter);

        return {
          ...prevFilter, // Preserve other filters
          guidance_type: [parentFilter], // Always set the parent filter
          publication_type: isChildActive
            ? [] // Uncheck the child if already selected
            : [filter], // Otherwise, select the new child
        };
      } else if (filter === "Publication") {
        // Handle toggling of the "Publication" parent filter
        const isPublicationActive = guidanceType.includes(filter);

        return {
          ...prevFilter, // Preserve other filters
          guidance_type: isPublicationActive
            ? guidanceType.filter((type: string) => type !== filter) // Remove "Publication"
            : [...guidanceType, filter], // Add "Publication"
          publication_type: isPublicationActive ? [] : [], // Clear all child filters
        };
      } else {
        // For other parent filters
        const isParentActive = guidanceType.includes(filter);

        return {
          ...prevFilter, // Preserve other filters
          guidance_type: isParentActive
            ? guidanceType.filter((type: string) => type !== filter) // Remove parent filter if active
            : [
                ...guidanceType.filter(
                  (type: string) => type !== "Publication"
                ),
                filter,
              ], // Add parent filter and ensure "Publication" is removed
          publication_type: [], // Clear child filters when toggling other parents
        };
      }
    });
  };

  const renderAuthorityFilterOptions = () => {
    const activeGuidanceType = guidanceFilter?.guidance_type || [];
    const activePublicationType = guidanceFilter?.publication_type || [];

    // Process guidance type options and publication type with counts
    const filteredGuidanceType = mergeAndFilterCounts(
      GUIDANCE_TYPE_OPTIONS,
      facetedNavGuidanceFilters?.guidance_type || []
    );

    const selectedGuidanceTypeFilter = selectedGuidanceType
      ? filteredGuidanceType.filter((t) => t.key === selectedGuidanceType)
      : filteredGuidanceType;

    const filteredPublicationType = mergeAndFilterCounts(
      PUBLICATION_TYPE,
      facetedNavGuidanceFilters?.publication_type || []
    );

    // Sort the data
    const sortedFilter = sortByHierarchy(selectedGuidanceTypeFilter as any);

    return (
      <>
        {sortedFilter.map((option, index) => {
          const isParentActive =
            option?.key !== "Publication" &&
            activeGuidanceType.includes(option?.key);
          const isPublicationActive =
            option?.key === "Publication" && activePublicationType.length > 0;

          return (
            <React.Fragment key={index}>
              <button
                onClick={() =>
                  handleAuthorityFilterClick(
                    option?.key,
                    option?.key === "Publication"
                  )
                }
                className={classNames("authorityFilters", {
                  ["active"]: isParentActive || isPublicationActive,
                  ["activeAuthorityFilters"]:
                    isParentActive || isPublicationActive,
                })}
              >
                {option?.value} (
                {option?.key === "Publication"
                  ? filteredPublicationType?.length
                  : option?.count}
                )
              </button>
              {/* Always show the children of the "Publication" filter */}
              {option?.key === "Publication" && (
                <div className="childAuthorityFilters">
                  {filteredPublicationType.map((childOption, childIndex) => (
                    <button
                      key={childIndex}
                      onClick={() =>
                        handleAuthorityFilterClick(childOption?.key, true)
                      }
                      className={classNames("childAuthorityFilter", {
                        ["active"]: activePublicationType.includes(
                          childOption?.key
                        ),
                        ["activeChildAuthorityFilter"]:
                          activePublicationType.includes(childOption?.key),
                      })}
                    >
                      {childOption?.value} ({childOption?.count})
                    </button>
                  ))}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const handleEntityFilterClick = (entityKey: string, filter: string) => {
    setGuidanceFilter((prevFilter: any) => {
      const currentFilter = { ...prevFilter };
      const currentActiveFilters = currentFilter[entityKey] || [];

      // Check if the filter is already selected
      if (currentActiveFilters.includes(filter)) {
        // Unselect the filter if it's already selected
        currentFilter[entityKey] = currentActiveFilters.filter(
          (activeFilter: string) => activeFilter !== filter
        );
      } else {
        // Add the filter if it's not selected
        currentFilter[entityKey] = [filter];
      }

      return currentFilter;
    });
  };

  const handleEntityFilterClickForCaseLaw = (
    entityKey: string,
    filter: string
  ) => {
    setCaselawFilter((prevFilter: any) => {
      const currentFilter = { ...prevFilter };
      const currentActiveFilters = currentFilter[entityKey] || [];

      if (currentActiveFilters.includes(filter)) {
        currentFilter[entityKey] = currentActiveFilters.filter(
          (activeFilter: string) => activeFilter !== filter
        );
      } else {
        currentFilter[entityKey] = [filter];
      }

      return currentFilter;
    });
  };

  const renderEntityFilterOptions = (
    entityKey: string,
    parsedFacets: UpdatedFacet[],
    activeFilter: string[],
    isCaselaw: boolean
  ) => {
    const shouldHideOptions = activeFilter.length > 0;

    return parsedFacets.map((facet) => {
      // Hide options if an active filter exists and it is not the selected option
      const isHidden = shouldHideOptions && !activeFilter.includes(facet.key);

      return (
        !isHidden && (
          <button
            key={facet.key}
            onClick={() =>
              isCaselaw
                ? handleEntityFilterClickForCaseLaw(entityKey, facet.key)
                : handleEntityFilterClick(entityKey, facet.key)
            }
            className={classNames("authorityFilters", {
              active: activeFilter.includes(facet.key),
              activeAuthorityFilters: activeFilter.includes(facet.key),
            })}
          >
            {facet.value} ({facet.count})
          </button>
        )
      );
    });
  };

  // Parse and sort facets
  const parsedEntityPersonFacets = sortArrayByProperty(
    parseFacets(facetedNavGuidanceFilters?.entity_person || []), // Use an empty array if entity_person is null/undefined
    "value"
  );
  const parsedEntityLocationFacets = sortArrayByProperty(
    parseFacets(facetedNavGuidanceFilters?.entity_location || []), // Use an empty array if entity_location is null/undefined
    "value"
  );

  const parsedEntityOrgFacets = sortArrayByProperty(
    parseFacets(facetedNavGuidanceFilters?.entity_organization || []), // Use an empty array if entity_organization is null/undefined
    "value"
  );

  const filterContent = (
    <div className="leftSide appliedLeftFilters">
      <div className="filterBlock">
        {isRegulations && (
          <FacetedNavFilter
            filters={facetedNavFilters as any}
            handleClick={handleRegulationFilterClick}
            activeFilters={regulationFilter?.reg_num || []}
          />
        )}
        {isGuidance || isCaselaw ? (
          <>
            {isGuidance ? (
              <div className="filterBody">
                <div className="filterHead">
                  <h2 className="filterTitle">Authority Level</h2>
                </div>
                {renderAuthorityFilterOptions()}
              </div>
            ) : null}
            {isArrayLength(parsedEntityPersonFacets) && (
              <EntityFilter
                title="People"
                tooltip="People entities are the names of people mentioned in the documents. They are extracted using a named entity recognition model."
                parsedFacets={parsedEntityPersonFacets}
                activeFilters={
                  isCaselaw
                    ? caselawFilter?.entity_person || []
                    : guidanceFilter?.entity_person || []
                }
                isCaselaw={isCaselaw}
                entityKey="entity_person"
                handleFilterClick={
                  isCaselaw
                    ? handleEntityFilterClickForCaseLaw
                    : handleEntityFilterClick
                }
              />
            )}

            {isArrayLength(parsedEntityLocationFacets) && (
              <EntityFilter
                title="Location"
                tooltip="Location entities are the names of locations mentioned in the documents. They are extracted using a named entity recognition model."
                parsedFacets={parsedEntityLocationFacets}
                activeFilters={
                  isCaselaw
                    ? caselawFilter?.entity_location || []
                    : guidanceFilter?.entity_location || []
                }
                isCaselaw={isCaselaw}
                entityKey="entity_location"
                handleFilterClick={
                  isCaselaw
                    ? handleEntityFilterClickForCaseLaw
                    : handleEntityFilterClick
                }
              />
            )}

            {isArrayLength(parsedEntityOrgFacets) && (
              <EntityFilter
                title="Organization"
                tooltip="Organization entities are the names of organizations mentioned in the documents. They are extracted using a named entity recognition model."
                parsedFacets={parsedEntityOrgFacets}
                activeFilters={
                  isCaselaw
                    ? caselawFilter?.entity_organization || []
                    : guidanceFilter?.entity_organization || []
                }
                isCaselaw={isCaselaw}
                entityKey="entity_organization"
                handleFilterClick={
                  isCaselaw
                    ? handleEntityFilterClickForCaseLaw
                    : handleEntityFilterClick
                }
              />
            )}
          </>
        ) : null}
      </div>
    </div>
  );

  return (
    <>
      {isMobile ? (
        <>
          <IconButton onClick={handleDrawerToggle}>
            <FilterListIcon /> Filter
          </IconButton>
          <Drawer
            anchor="bottom"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            sx={{
              "& .MuiDrawer-paper": {
                height: "70%",
                backgroundColor: "#FFFFFF",
                padding: "16px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              },
            }}
          >
            <div
              style={{
                backgroundColor: "#FFFFFF",
                padding: "16px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              {filterContent}
            </div>
          </Drawer>
        </>
      ) : (
        filterContent
      )}
    </>
  );
}
