import React, { useEffect, useRef, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import type {
  ToolbarSlot,
  TransformToolbarSlot,
} from "@react-pdf-viewer/toolbar";
import { searchPlugin } from "@react-pdf-viewer/search";
import { useMediaQuery } from "@mui/material";
import Cookies from "js-cookie";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import './PdfViewer.css';

interface PdfViewerProps {
  fileUrl: string;
  searchTerm?: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ fileUrl, searchTerm = "" }) => {
  const searchPluginInstance = searchPlugin();
  const { highlight } = searchPluginInstance;

  const toolbarPluginInstance = toolbarPlugin();
  const { Toolbar, renderDefaultToolbar } = toolbarPluginInstance;

  const transformToolbarSlot: TransformToolbarSlot = (
    slot: ToolbarSlot
  ): ToolbarSlot => ({
    ...slot,
    Open: () => <></>, // Remove "Open File" button
    EnterFullScreen: () => <></>, // Remove "Full Screen" button
  });

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) =>
      defaultTabs.filter(
        (tab) => tab.title !== "Attachment" && tab.title !== "Bookmark"
      ),
    toolbarPlugin: {
      searchPlugin: {
        keyword: [searchTerm],
      },
    },
  });

  const isMobile = useMediaQuery("(max-width:600px)");
  const viewerStyle = isMobile
    ? { width: "100%", height: "calc(100vh - 20px)", overflow: "hidden" }
    : { height: "720px" };

  const viewerRef = useRef<HTMLDivElement | null>(null);
  const [zoomLevel, setZoomLevel] = useState<number>(
    parseFloat(Cookies.get("zoomLevel") || "1")
  );

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (viewerRef.current && searchTerm && highlight) {
        highlight(searchTerm);
      }
    });

    if (viewerRef.current) {
      observer.observe(viewerRef.current, { childList: true, subtree: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [searchTerm, highlight]);

  const handleZoomChange = (e: { scale: number }) => {
    setZoomLevel(e.scale);
    Cookies.set("zoomLevel", e.scale.toString(), { expires: 7 });
  };

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
      <div ref={viewerRef} style={viewerStyle}>
        <Viewer
          fileUrl={fileUrl}
          enableSmoothScroll
          defaultScale={zoomLevel}
          onZoom={handleZoomChange}
          plugins={[
            defaultLayoutPluginInstance,
            searchPluginInstance,
            toolbarPluginInstance,
          ]}
        />
        <Toolbar>
          {renderDefaultToolbar(transformToolbarSlot)}
        </Toolbar>
      </div>
    </Worker>
  );
};

export default PdfViewer;
