import { PUBLICATION_TYPE } from "./search-view.constants";

export const TabOption ={
  "case-law": "Case Law",
  "regulations": "Regulation",
  "guidance": "guidance",
}
interface FacetFieldItem {
  title: string; // Regulation number
  count: number; // Count of occurrences
}

interface FacetCounts {
  facet_fields: {
    reg_num: FacetFieldItem[];
  };
}

interface RegulationDataItem {
  reg: string; // Regulation number
  title: string; // Regulation title
}

interface RegulationWithCount {
  reg: string; // Regulation number
  title: string; // Regulation title
  count: number; // Count of occurrences
}

export const isArrayLength = (arr: any[]): boolean =>
  Array.isArray(arr) && arr.length > 0;

/**
 * Replaces all occurrences of &sect; with § in the given string.
 *
 * @param {string} input - The input string to replace the entity.
 * @returns {string} - The modified string with &sect; replaced by §.
 */
export const replaceSectionEntity = (input: string): string => {
  return input?.replace(/&sect;/g, "§");
};

export const generateInitialRegulationsList = (checkedItems: number[]) => {
  return checkedItems.reduce((acc, item) => {
    acc[item] = { checked: true };
    return acc;
  }, {} as Record<number, { checked: boolean }>);
};

export const parseUrlToOriginal = (search: string): string | null => {
  // Create a URLSearchParams instance to parse the query string
  const params = new URLSearchParams(search);

  // Get the keyword parameter
  const keyword = params.get("keyword");
  if (!keyword) return null;

  // Replace hyphens with spaces and capitalize each word
  return keyword
    ?.split("-")
    ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1))
    ?.join(" ");
};

export const filterRegulations = (
  facetCounts: FacetCounts,
  regulationsData: RegulationDataItem[]
): RegulationWithCount[] => {
  // Extract valid regulations with count > 0
  const validRegNums = facetCounts.facet_fields.reg_num.filter(
    (item) => item.count > 0
  );

  // Map regulationsData to include the count
  return regulationsData
    .map((regulation) => {
      const match = validRegNums.find((item) => item.title === regulation.reg);
      return match
        ? {
            reg: regulation.reg,
            title: regulation.title,
            count: match.count,
          }
        : null;
    })
    .filter((item): item is RegulationWithCount => item !== null); // Filter out unmatched regulations
};

export const cleanSearchTerm = (term: string): string => {
  // Use regex to remove commas and numbers
  return term.replace(/,|\d+/g, "").trim();
};

export const transformSearchTerm = (term: string): string => {
  return term.replace(/,/g, " > ").trim();
};

/**
 * Renders the highlighted text for a given highlighting object based on the specified highlight fields.
 *
 * @param highlighting - The object containing highlight data.
 * @param highlightFields - An array of fields to check for highlighting, in order of preference.
 * @returns The formatted highlight string or an empty string if no highlighting is found.
 */
export const renderHighlight = (
  highlighting: any,
  highlightFields: string[] = ["_text_ngram_", "_text_"]
): string => {
  for (const field of highlightFields) {
    if (highlighting?.[field]) {
      return highlighting[field]
        .join(" ...") // Join the highlighted text fragments
        .replace(/em>/gi, "b>"); // Replace <em> tags with <b> tags
    }
  }
  return ""; // Default to an empty string if no highlighting is found
};

/**
 * Helper function to split the input into keyword and reg_num
 * @param {string} input - The input string to be processed
 * @returns {Object} - An object containing keyword and reg_num
 */
export function parseSearchInput(input: any) {
  if (!input) return { keyword: "", reg_num: "" };

  const match = input.match(/([^\d]+)?(\d+)?/); // Match letters and numbers separately
  let keyword = match[1]?.replace(/,/g, "").trim() || ""; // Remove commas and trim spaces

  // Remove occurrences of "null" in the keyword
  keyword = keyword.replace(/null/gi, "").trim();

  let reg_num = match[2] || ""; // Extract the numeric part (reg_num)

  return { keyword, reg_num };
}

type GuidanceFilter = {
  guidance_type: string[];
  publication_type: string[];
  entity_person: string[];
  entity_location: string[];
  entity_organization: string[];
};

type CaseLawFilter = {
  entity_person: string[];
  entity_location: string[];
  entity_organization: string[];
};

type Filter = GuidanceFilter | CaseLawFilter;

/**
 * Helper function to validate and sanitize guidance, publication, and entity filters
 * @param {Filter} filter - The current filter state
 * @returns {Object | null} - The sanitized filter object or null
 */
export function validateFacetsFilter(filter: Filter): Record<string, any> | null {
  const {
    guidance_type = [],
    publication_type = [],
    entity_person = [],
    entity_location = [],
    entity_organization = [],
  } = filter as GuidanceFilter;

  // Case 1: If guidance_type includes "Publication" but publication_type is empty
  if (
    'guidance_type' in filter &&
    'publication_type' in filter &&
    guidance_type.includes("Publication") &&
    publication_type.length === 0
  ) {
    return null;
  }

  // Case 2: If all guidance_type, publication_type, and entities are empty
  if (
    (!('guidance_type' in filter) || guidance_type.length === 0) &&
    (!('publication_type' in filter) || publication_type.length === 0) &&
    entity_person.length === 0 &&
    entity_location.length === 0 &&
    entity_organization.length === 0
  ) {
    return null;
  }

  // Case 3: If guidance_type[0] and publication_type[0] are both "Publication"
  if (
    'guidance_type' in filter &&
    'publication_type' in filter &&
    guidance_type[0] === "Publication" &&
    publication_type[0] === "Publication"
  ) {
    const mappedTitles = PUBLICATION_TYPE.map((facet: any) => facet.key);
    return removeEmptyKeys({
      guidance_type,
      publication_type: mappedTitles,
      entity_person: entity_person.slice(0, 1), // Ensure only one person is selected
      entity_location: entity_location.slice(0, 1), // Ensure only one location is selected
      entity_organization: entity_organization.slice(0, 1), // Ensure only one organization is selected
    });
  }

  // Default case: Sanitize and ensure only one selection per entity
  return removeEmptyKeys({
    guidance_type: 'guidance_type' in filter ? guidance_type : undefined,
    publication_type:
      'publication_type' in filter &&
      Array.isArray(publication_type) &&
      publication_type.length > 0
        ? publication_type[0]
        : undefined,
    entity_person: entity_person.slice(0, 1), // Ensure only one person is selected
    entity_location: entity_location.slice(0, 1), // Ensure only one location is selected
    entity_organization: entity_organization.slice(0, 1), // Ensure only one organization is selected
  });
}


// Helper function to remove keys with empty arrays
function removeEmptyKeys(obj: Record<string, any>): Record<string, any> {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => 
      !(Array.isArray(value) && value.length === 0)
    )
  );
}


// Generic helper function to sort objects alphabetically by a specified property
export function sortArrayByProperty<T>(array: T[], property: keyof T): T[] {
  return array.sort((a, b) => {
    const valueA = String(a[property]).toLowerCase();
    const valueB = String(b[property]).toLowerCase();
    return valueA.localeCompare(valueB);
  });
}

export const capitalizeFirstLetter = (str: string | null | undefined): string | null => {
  if (typeof str !== 'string' || str.length === 0) {
    return null;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const canActAsAppAdmin = (loggedUser: any): boolean => {
  if (loggedUser?.isAdmin || loggedUser?.roles?.includes('editor') || loggedUser?.roles?.includes('author')){
    return true;
  }
  return false;
}
