import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import {
  InputBase,
  Typography,
  Modal,
  Box,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import SuggestedAndPopularSearches from "./SuggestedAndPopularSearches";

import "./SearchBaseView.css";
import { INDUSTRY_OPTIONS } from "../../../constants/search-view.constants";

const SearchBaseView: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<{
    commodity: string[];
    miningMethod: string[];
  }>({ commodity: [], miningMethod: [] });
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [error, setError] = useState(""); // State for validation error
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [industries, setIndustries] = useState<string[]>([]);

  const navigate = useNavigate();

  // Show Modal on First Visit
  useEffect(() => {
    const isFirstLogin = !localStorage.getItem("searchGuideShown");
    if (isFirstLogin) {
      setShowModal(true);
      localStorage.setItem("searchGuideShown", "true");
    }
  }, []);
  const searchTermLong = "Enter your search term or question";
  const searchTermShort = "Search term or question";
  const searchTermVeryShort = "Search";
  
  const [placeholder, setPlaceholder] = useState(
    window.innerWidth > 512
      ? searchTermLong
      : window.innerWidth > 438
      ? searchTermShort
      : searchTermVeryShort
  );
  
  useEffect(() => {
    const handleResize = () => {
      setPlaceholder(
        window.innerWidth > 512
          ? searchTermLong
          : window.innerWidth > 438
          ? searchTermShort
          : searchTermVeryShort
      );
    };
  
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
  
    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const handleIndustryChange = (industry: string) => {
    setSelectedIndustries((prev) => {
      if (industry === "ALL") {
        // If "ALL" is selected, deselect everything else
        return prev.includes("ALL") ? [] : ["ALL"];
      } else {
        // If selecting a single industry, remove "ALL" if it's selected
        const filteredPrev = prev.filter((i) => i !== "ALL");
        return filteredPrev.includes(industry)
          ? filteredPrev.filter((i) => i !== industry)
          : [...filteredPrev, industry];
      }
    });
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      executeSearch();
    }
  };

  const executeSearch = () => {
    if (!searchTerm.trim()) {
      setError("Please enter a search term.");
      return;
    }
    setError("");
    const queryParams: string[] = [];
    if (selectedIndustries.length > 0) {
      queryParams.push(`industries=${selectedIndustries.join(",")}`);
    }
    queryParams.push(`keyword=${searchTerm}`);
    navigate(`/search/guidance?${queryParams.join("&")}`);
  };

  return (
    <div className="searchFilterWrapper">
      <div className="contentWidth">
        <div className="searchHeadContent">
          <h1>Get compliance insights</h1>
          <p>
            Enter your search criteria and access thousands of laws,
            regulations, and guidance documents.
          </p>
        </div>

        <div className="searchWrapper">
          <div className="search">
            <div className="searchLeft">
              <InputBase
                placeholder={placeholder}
                classes={{ root: "inputRoot", input: "inputInput" }}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyPress}
                error={!!error}
              />
            </div>
            <div className="searchIcon">
              <IconButton
                onClick={() => {
                  executeSearch();
                }}
                style={{
                  color: searchTerm ? "#2f80ed" : "grey",
                }}
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                onClick={toggleDropdown}
                style={{
                  color: showDropdown ? "#2f80ed" : "grey",
                }}
              >
                <TuneRoundedIcon />
              </IconButton>
            </div>
          </div>
          <div className="infoIcon">
            {/* Info Icon outside the box */}
            <IconButton
              onClick={() => setShowModal(true)}
              style={{
                color: showModal ? "#2f80ed" : "grey",
              }}
            >
              <InfoIcon fontSize="medium" />
            </IconButton>
          </div>
        </div>
        {error && (
          <FormHelperText style={{ color: "#f66e2f", marginTop: "4px" }}>
            {error}
          </FormHelperText>
        )}

        {showDropdown && (
          <div className="searchDropdown">
            <FormGroup>
              <Typography variant="h6">Industry</Typography>
              <div className="industryOptions">
              {INDUSTRY_OPTIONS.slice(1).map((industry) => (
                <FormControlLabel
                  key={industry?.key}
                  control={
                    <Checkbox
                      checked={selectedIndustries.includes(industry?.key)}
                      onChange={() => handleIndustryChange(industry?.key)}
                      disabled={
                        selectedIndustries.includes("ALL") &&
                        industry?.key !== "ALL"
                      } // Disable individual options if "ALL" is selected
                    />
                  }
                  label={industry?.value}
                />
              ))}
              </div>
            </FormGroup>
            <Typography
              variant="caption"
              style={{
                fontFamily: "Label S", // Replace with actual style reference
                marginTop: "8px",
                color: "#555",
              }}
            >
              Industry filters apply to Regulations and Guidance documents only
            </Typography>
          </div>
        )}

        <div className="filterSec">
          {selectedIndustries.map((industry) => (
            <span key={industry} className="appliedFilter">
              {industry}{" "}
              <span
                className="deleteFilter"
                onClick={() => handleIndustryChange(industry)}
              >
                ✕
              </span>
            </span>
          ))}
        </div>
        <SuggestedAndPopularSearches />

        {/* Modal */}
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <Box className="modalStyle">
            <div className="modalHeader">
              <Typography
                className="modalTitle"
                variant="h6"
                sx={{
                  color: "#0690c6",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontSize: "20px",
                }}
              >
                Search Tips
              </Typography>
              <IconButton onClick={() => setShowModal(false)}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className="modalContent">
              {/* Search Tips */}
              <div className="contentBlock">
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  OSHAWISE uses fuzzy search, meaning we take into account
                  misspellings, plurals, word stems, and synonyms so that you
                  can often find what you need even without an exact match.
                </Typography>

                <Box
                  className="highlightBox"
                  sx={{
                    mt: 2,
                    mb: 2,
                    bgcolor: "#0690c6",
                    borderRadius: "10px",
                    padding: "16px",
                  }}
                >
                  <ul
                    style={{ margin: "0", paddingLeft: "20px", color: "#fff" }}
                  >
                    <li>
                      A keyword search like <strong>“silica dust”</strong> is
                      often better for returning relevant documents.
                    </li>
                    <li>
                      A question or phrase like{" "}
                      <strong>“are customer truck drivers miners”</strong> is
                      often better for AI Copilot results.
                    </li>
                  </ul>
                </Box>
                <hr style={{ backgroundColor: "#ddd", opacity: "0.2" }} />
              </div>
              <div className="contentBlock">
                {/* Search Operators */}
                <Typography
                  className="modalTitle"
                  variant="h6"
                  sx={{
                    color: "#0690c6",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    fontSize: "20px",
                  }}
                >
                  SEARCH OPERATORS
                </Typography>

                <Typography variant="body2" sx={{ mt: 1 }}>
                  <strong>Exact Match (quotes)</strong> - Using quotes will
                  perform an exact word or phrase match, meaning the exact
                  phrase MUST appear in the documents found. Will not usually
                  impact Copilot results.
                  <br />
                  <div className="exampleSec">
                    Example: <code>"brake test"</code>
                  </div>
                </Typography>

                <Typography variant="body2" sx={{ mt: 2 }}>
                  <strong>AND Operator</strong> - Using <strong>AND</strong> in
                  a search ensures all specified conditions must be satisfied,
                  meaning all terms connected by <strong>AND</strong> MUST
                  appear in the documents found. This operator narrows the
                  search to return only results that meet every specified
                  criterion. Will not usually impact Copilot results.
                  <br />
                  <div className="exampleSec">
                    Example: <code>Term1 AND Term2</code>
                  </div>
                </Typography>

                <Typography variant="body2" sx={{ mt: 2 }}>
                  <strong>OR Operator</strong> - Using <strong>OR</strong> in a
                  search broadens the results by requiring that at least one of
                  the specified conditions is satisfied, meaning any term
                  connected by <strong>OR</strong> MAY appear in the documents
                  found. This operator is useful for expanding search results to
                  include more matches. Will not usually impact Copilot results.
                  <br />
                  <div className="exampleSec">
                    Example: <code>Term1 OR Term2</code>
                  </div>
                </Typography>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default SearchBaseView;
