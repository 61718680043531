import { isArray, isNil } from "lodash";
import { normalize, schema } from "normalizr";
import { put } from "redux-saga/effects";

export const userSchema = new schema.Entity("users");
export const documentReferenceSchema = new schema.Entity("document_references");
export const documentReferenceTypeSchema = new schema.Entity("documentReferenceTypes");
export const complianceInsightSchema = new schema.Entity("complianceInsights");
export const topSeachSchema = new schema.Entity("topSearches");

export function autoYields(data: any): any[] {

  const overrideResult = 'overrideResult'
  const customHandle = 'customHandle'

  const yields = [] as any[];
  const pData = {
    loggedUser: customHandle,
    user: userSchema,
    users: [userSchema],
    document_reference: documentReferenceSchema,
    document_references: [documentReferenceSchema],
    documentReferenceType: documentReferenceTypeSchema,
    documentReferenceTypes: [documentReferenceTypeSchema],
    complianceInsight: complianceInsightSchema,
    complianceInsights: [complianceInsightSchema],
    system_settings: customHandle,
    topSearches: overrideResult,
  } as any;

  Object.keys(data).forEach((property: string) => {

    if (pData[property]) {
      const successType = "REDUCE_" + property + "_SUCCESS";

      switch (pData[property]) {
        case overrideResult:
          yields.push(
            put({
              type: successType,
              overrides: [
                {
                  key: property,
                  value: data[property]
                }
              ],
            })
          );
          break;
        case customHandle:
          switch (property) {
            case 'loggedUser':
              window.localStorage.email = data[property].email;
              window.localStorage.name = data[property].name;
              window.localStorage.id = data[property].id;

              yields.push(
                put({
                  type: "REDUCE_MERGE_" + property + "_SUCCESS",
                  mergeKeys: [{
                    key: "loggedUser",
                    value: data[property]
                  }],
                }),
              );
              break;
            case 'system_settings':
              yields.push(
                put({
                  type: "SYSTEM_SETTINGS_SET_SUCCESS",
                  overrides: [{
                    key: "system_settings",
                    value: data[property]
                  }],
                }),
              );
              break;
          }

          break;
        default:
          if (!isNil(data[property])) {
            yields.push(
              put({
                type: successType,
                normalized: normalize(data[property], pData[property]),
              })
            );
          }
          break;
      }
    } else if (property.startsWith("deleted_")) { // check for deleted keys
      const compareString = property.replace("deleted_", "");
      if (pData[compareString]) {
        let deleteIds = data[property];
        let stateKey = "";
        let idAttribute = "";
        if (!isArray(deleteIds)) {
          deleteIds = [deleteIds];
        }
        if (isArray(pData[compareString])) {
          stateKey = pData[compareString][0]._key;
          idAttribute = pData[compareString][0]._idAttribute;
        } else {
          stateKey = pData[compareString]._key;
          idAttribute = pData[compareString]._idAttribute;
        }
        deleteIds = deleteIds.map((obj: any) => obj[idAttribute]);
        const removeKeys = [{ entities: stateKey, ids: deleteIds }];
        yields.push(put({ type: "REDUCE_DELETE_" + property + "_SUCCESS", removeKeys, forceStateRefresh: true }));
      }
    }
  });
  return yields;
}
