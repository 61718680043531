import React, { useState } from "react";
import classNames from "classnames";

// Define the type for filter items
interface FilterItem {
  reg: string;
  title: string;
  count: number;
}

// Props for the child component
interface FacetedNavFilterProps {
  filters: FilterItem[];
  handleClick: (reg: string) => void;
  activeFilters: string[];
}

const FacetedNavFilter: React.FC<FacetedNavFilterProps> = ({
  filters,
  handleClick,
  activeFilters,
}) => {
  const [showAll, setShowAll] = useState(false);

  // Sort filters by count in descending order
  const sortedFilters = [...filters].sort((a, b) => b.count - a.count);

  // Limit displayed filters
  const visibleFilters = showAll ? sortedFilters.slice(0, 30) : sortedFilters.slice(0, 10);

  return (
    <div className="filterBody">
      <div className="filterHead">
        <h3 className="filterTitle">Faceted Navigation</h3>
      </div>
      {visibleFilters.map((filter, index) => (
        <button
          key={index}
          onClick={() => handleClick(filter.reg)}
          className={classNames("facetedNavFilters", {
            ["activeFacetedNavFilters"]: activeFilters.includes(filter.reg),
          })}
        >
          {`${filter.reg} - ${filter.title} (${filter.count || 0})`}
        </button>
      ))}
      {sortedFilters.length > 10 && (
        <button
          onClick={() => setShowAll(!showAll)}
          className="toggleFiltersButton"
        >
          {showAll ? "Show Less" : "Show More"}
        </button>
      )}
    </div>
  );
};

export default FacetedNavFilter;
