import React, { useState, useEffect } from "react";
import DOMPurify from "dompurify"; // To sanitize incoming HTML (npm install dompurify)

const StreamedHTMLRenderer = ({ streamedText }: any) => {
  const [htmlContent, setHtmlContent] = useState(""); // Accumulate the streamed HTML

  // Update the HTML content when the prop changes
  useEffect(() => {
    if (streamedText) {
      setHtmlContent((prevContent) => {
        const combinedContent = streamedText;
        return ensureValidHTML(combinedContent);
      });
    }
  }, [streamedText]);

  // Function to ensure valid HTML by auto-closing tags
  const ensureValidHTML = (html: any) => {
    // Use DOMPurify to sanitize and auto-fix incomplete HTML
    const sanitizedHTML = DOMPurify.sanitize(html, { WHOLE_DOCUMENT: false });
    return sanitizedHTML;
  };

  return (
      <span
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      ></span>
  );
};

export default StreamedHTMLRenderer;
